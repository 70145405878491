import React, { useState, useEffect, useContext } from 'react';
import { MyContext } from '../../context/MyProvider';

const ExpenseFilter = () => {

    const { setExpenseReportFilteredData, selectedPropertiesForExpense, setSelectedPropertiesForExpense } = useContext(MyContext);



    const data = {

    }


    const handleCheckboxChange = (property) => {
        setSelectedPropertiesForExpense(prevState => ({
            ...prevState,
            [property]: !prevState[property],
        }));
    };



    return (
        <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ width: "80%" }}>
                {/* Checkboxes for selecting properties */}
                {Object.keys(selectedPropertiesForExpense).map(property => (
                    <label className='left_box_check' key={property}>
                        <input
                            type="checkbox"
                            defaultChecked
                            className='input_check'
                            checked={selectedPropertiesForExpense[property]}
                            onChange={() => handleCheckboxChange(property)}
                        />
                        {property}
                    </label>
                ))}
                {Object.keys(data).map(property => (
                    <label className='left_box_check' key={property}>
                        <input
                            type="checkbox"
                            defaultChecked
                            className='input_check'
                            checked={data[property]}
                        />
                        {property}
                    </label>
                ))}


            </div>
        </div>
    );
};

export default ExpenseFilter;
