import React, { useContext } from 'react';
import Papa from 'papaparse';
import { saveAs } from 'file-saver';
import { MyContext } from '../../context/MyProvider';

const Csv = ({ companyName }) => {
  const { reportFilteredData } = useContext(MyContext);

  const generateCsv = () => {
    // Convert JSON data to CSV format using papaparse
    if (!reportFilteredData || reportFilteredData.length === 0) {
      // Handle the case when there is no data
      return;
    }

    const excludeFields = ['_id', 'userID', 'companyID', 'createdAt', 'updatedAt', '__v', 'sendToQb', 'fromQb', 'photo', 'description', 'merchant', 'expenseReport', 'paidBy', 'name'];

    const newArray = reportFilteredData.map(obj => {
      const filteredObject = Object.fromEntries(
        Object.entries(obj).filter(([key]) => !excludeFields.includes(key))
      );
      return filteredObject;
    });

    const startDate = reportFilteredData[0]?.Date;
    const endDate = reportFilteredData[reportFilteredData.length - 1]?.Date;

    // Convert data to CSV format using papaparse
    const csvData = Papa.unparse(newArray, {
      header: true,
    });

    // Adding custom heading row
    const csvWithHeading = `Company: ${companyName}, Duration: ${startDate} to ${endDate}\n\n${csvData}`;

    // Create a Blob from the CSV data
    const blob = new Blob([csvWithHeading], { type: 'text/csv;charset=utf-8' });

    // Trigger a file download
    saveAs(blob, `${companyName}.csv`);
  };

  return (
    <div>
      <button className="csv_button" onClick={generateCsv}>Export CSV</button>
    </div>
  );
};

export default Csv;
