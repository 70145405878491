import React, { useContext } from 'react';
import * as XLSX from 'xlsx';
import { MyContext } from '../../context/MyProvider';

const ExpenseExcel = ({ companyName }) => {
  const { expenseReportFilteredData } = useContext(MyContext);

  const convertJsonToExcel = () => {
    if (!expenseReportFilteredData || expenseReportFilteredData.length === 0) {
      // Handle the case when there is no data
      return;
    }

    const excludeFields = ['_id', 'userID', 'companyID', 'createdAt', 'updatedAt', '__v', 'sendToQb', 'userName', 'fromQb', 'photo', 'description', 'merchant', 'expenseReport', 'paidBy', 'name', 'rejectReason', 'demo'];

    const newArray = expenseReportFilteredData.map(obj => {
      const filteredObject = Object.fromEntries(
        Object.entries(obj).filter(([key]) => !excludeFields.includes(key))
      );
      return filteredObject;
    });

    const startDate = newArray[0]?.Date;
    const endDate = newArray[newArray.length - 1]?.Date;

    // Prepare heading and data
    const heading = [{ Company: companyName, Duration: `${startDate} - ${endDate}` }];
    const dataWithHeadings = [...heading, ...newArray];

    // Convert JSON to Excel
    const ws = XLSX.utils.json_to_sheet(dataWithHeadings);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    // Save the Excel file
    XLSX.writeFile(wb, `${companyName}.xlsx`);
  };

  return (
    <div>
      <button className="csv_button" onClick={convertJsonToExcel}>Export Excel</button>
    </div>
  );
};

export default ExpenseExcel;
