


import * as React from 'react';
import { useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import axios from 'axios';
import "./TableDesign.css"
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Switch from '@mui/material/Switch';

const columns = [
    { id: 'Name', label: 'Name', minWidth: 170 },
    { id: 'Type', label: 'Type', minWidth: 170 },
    {
        id: 'taskcreated',
        label: 'Created At',
        minWidth: 170,
        align: 'center'
    },
    { id: 'Status', label: 'Status', minWidth: 100 }
];

// temporory data

const data = [
    {
        project: 'Development',
        status: 'Active',
        createdAt: '2023-06-07T04:12:17.986Z'
    },
    {
        project: 'Development',
        status: 'Active',
        createdAt: '2023-06-07T04:12:17.986Z'
    },
    {
        project: 'Development',
        status: 'Active',
        createdAt: '2023-06-07T04:12:17.986Z'
    },

];

export default function StickyHeadTable({ chartOfAccountInfo, handleDataFromChild }) {

    const adminloginInfo = JSON.parse(localStorage.getItem("adminLoginInfo"));
    const userLoginInfo = JSON.parse(localStorage.getItem("userLoginInfo"));
    if (adminloginInfo) {
        var companyID = adminloginInfo?._id;
        var token = adminloginInfo?.token
        var userID = adminloginInfo._id;
        var userName = adminloginInfo.name;
    }
    if (userLoginInfo) {
        var companyID = userLoginInfo?.companyID;
        var token = userLoginInfo?.token
        var userID = userLoginInfo._id;
        var userName = userLoginInfo.name;
    }


    //config 

    const config = {
        headers: {
            Accept: 'application/json',
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    };


    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };


    const handleSwitchChange = async (e, accountId) => {
        try {
            const { data } = await axios.post(
                `https://app.timetraker.com/backend/api/active-account/?accountId=${accountId}`,
                { checked: e.target.checked },
                config
            );
            if (data) {
                handleDataFromChild(data);
                ChartOfAccountActiveNotfy();
            }

        } catch (err) {
            console.log(err);
        }
    }

    const ChartOfAccountActiveNotfy = () => {
        toast.success('Chart Of Account Status Has been Changed', {
            position: 'top-right',
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    }






    return (
        <Paper sx={{ width: '100%', overflow: 'auto' }}>
            <TableContainer>
                <Table stickyHeader aria-label="sticky table" style={{ overflow: 'hidden' }}>
                    <TableHead >
                        <TableRow >
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth, background: '#04542C', color: '#fff', textAlign: 'center' }}>
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody style={{ overflow: 'hidden' }}>
                        {chartOfAccountInfo
                            ? (rowsPerPage > 0 ? chartOfAccountInfo.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : chartOfAccountInfo)?.map((item, index) => {
                                const createdAt = new Date(item?.createdAt);

                                const options = {
                                    year: 'numeric',
                                    month: '2-digit',
                                    day: '2-digit',
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    second: '2-digit',
                                    hour12: false,
                                    timeZone: 'Asia/Kolkata'
                                };

                                function shortenString(str) {
                                    if (str.length > 20) {
                                        return str.substring(0, 20) + '...';
                                    }
                                    return str;
                                }

                                const formattedDate = createdAt.toLocaleString('en-IN', options);
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                        <TableCell title={item?.name} >{shortenString(item?.name)}</TableCell>
                                        <TableCell title={item?.type}>{shortenString(item?.type)}</TableCell>
                                        <TableCell align="center">{formattedDate} </TableCell>
                                        <TableCell style={{ textAlign: 'center' }}>{<Switch checked={item?.status} onChange={(e) => { handleSwitchChange(e, item._id) }} />}</TableCell>
                                    </TableRow>
                                );
                            })
                            : data?.map((item, index) => {
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                        <TableCell align="center">{item?.task}</TableCell>
                                        <TableCell align="center">{item?.status}</TableCell>
                                        <TableCell align="center">{item?.createdAt} </TableCell>
                                        <TableCell style={{ textAlign: 'center' }}>{<Switch checked={item?.active} onChange={(e) => { handleSwitchChange(e, item._id) }} />}</TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                component="div"
                count={chartOfAccountInfo?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />

        </Paper>
    );
}
