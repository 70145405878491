import React, { useEffect, useState, useContext } from 'react'
import ExpenseExcel from './ExpenseExcel'
import ExpenseCsv from './ExpenseCsv'
import ExpensePdf from './ExpensePDF'
import ExpenseFilter from './ExpenseFilter'
import axios from 'axios'
import Form from "react-bootstrap/Form";
import './TimesheetReport.css'
import AdminLayout from "../../hoc/AdminLayout";
import { MyContext } from '../../context/MyProvider'
import { Col, Container, Row } from "react-bootstrap";
import signupImg1 from "../../img/Vector.png"
import signupImg2 from "../../img/user2.png"
import { Dialog, Grid } from '@mui/material'
import ReportExpenseTable from '../../components/ReportExpenseTable'
import { useNavigate } from 'react-router-dom'
import ChatAfterLogin from "../../components/chat/ChatAfterLogin";
import ProfileComponent from "../../components/ProfileComponent";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Slide from "@mui/material/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const ExpenseReport = () => {


  const { sideBarStatus, setSideBarStatus } = useContext(MyContext);

  const handleClick = () => {
    if (sideBarStatus == true) {
      setSideBarStatus(false)
    } else {
      setSideBarStatus(true)
    }
  }


  const screenWidth = window.innerWidth;
  if (screenWidth >= 840) {
    var forPhoneScreenNoDisplay = true
  } else {
    var forPhoneScreenNoDisplay = false
  }


  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);

  };

  const handleClose = () => {
    setOpen(false);
  };




  const [allUserExpenseInfo, setAllUserExpenseInfo] = useState()

  const adminloginInfo = JSON.parse(localStorage.getItem('adminLoginInfo'));
  const userLoginInfo = JSON.parse(localStorage.getItem('userLoginInfo'));


  if (adminloginInfo) {
    var companyID = adminloginInfo._id
    var token = adminloginInfo?.token
  }
  else if (userLoginInfo) {
    var companyID = userLoginInfo.companyID
    var token = userLoginInfo?.token
  }

  // config 

  const config = {
    headers: {
      "Accept": "application/json",
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  }


  const navigate = useNavigate()

  const [allUser, setAllUser] = useState([]);
  const [allManager, setAllManager] = useState([]);
  const [allSubAdmin, setAllSubAdmin] = useState([]);
  const [allSuperAdmin, setAllSuperAdmin] = useState([]);
  const [companyName, setCompanyName] = useState('');


  const { expenseReportFilteredData, setExpenseReportFilteredData, selectedPropertiesForExpense } = useContext(MyContext);




  const getAllUserExpense = async () => {
    try {
      const { data } = await axios.get(`https://app.timetraker.com/backend/api/all-expense-report/?companyID=${companyID}`, config)
      setAllUserExpenseInfo(data.expenses)
      setExpenseReportFilteredData(data.expenses)
    } catch (error) {
      console.log(error)
    }
  }


  const getAllUser = async () => {
    try {
      const { data } = await axios.get(`https://app.timetraker.com/backend/api/all-users-report/?companyID=${companyID}`, config)
      // allTheEmployee.push(...data.allUsers)

      setAllUser(data.allUsers)
    } catch (error) {
      console.log("error from get all user api", error)
    }
  }


  const getAllManager = async () => {
    try {
      const { data } = await axios.get(`https://app.timetraker.com/backend/api/all-manager-report/?companyID=${companyID}`, config)
      // allTheEmployee.push(...data.allManager)
      setAllManager(data.allManager)
    } catch (error) {
      console.log("error from get all user api", error)
    }
  }

  const getAllSubAdmin = async () => {
    try {
      const { data } = await axios.get(`https://app.timetraker.com/backend/api/all-sub-admin-report/?companyID=${companyID}`, config)
      // allTheEmployee.push(...data.allSubAdmin)
      setAllSubAdmin(data.allSubAdmin)
    } catch (error) {
      console.log("error from get all user api", error)
    }
  }

  const getSuperAdmin = async () => {
    try {
      const { data } = await axios.get(
        `https://app.timetraker.com/backend/api/super-admin/?companyID=${companyID}`,
        config
      );
      setAllSuperAdmin(data.superAdmin)
    } catch (error) {
      console.log("error from get all user api", error);
    }
  };





  // filter

  const [projectInfo, setProjectInfo] = useState([]);
  const [chartOfAccount, setChartOfAccount] = useState([]);

  // get all project

  const getAllProject = async () => {
    try {
      const { data } = await axios.get(
        `https://app.timetraker.com/backend/api/all-project-for-admin/?companyID=${companyID}`,
        config
      );
      setProjectInfo(data.projects);
    } catch (error) {
      console.log('error', error)
    }
  };

  // get all task

  const getAllChartOfAccount = async () => {
    try {
      const { data } = await axios.get(
        `https://app.timetraker.com/backend/api/get-chart-of-account-for-admin/?companyID=${companyID}`,
        config
      );
      setChartOfAccount(data.chartOfAccount);
    } catch (error) {
      console.log(error);
    }
  };



  const [filterFormData, setFilterFormData] = useState({
    companyID: companyID
  })

  const [downloadFilter, setDownloadFilter] = useState('')

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFilterFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setExpenseReportFilteredData([])
    handleClickOpen()
    try {
      const { data } = await axios.post(`https://app.timetraker.com/backend/api/filtered-expense-report/?companyID=${companyID}`, filterFormData, config)

      if (downloadFilter === '') {
        const filterData = (data, selectedProperties) => {
          const keysToKeep = Object.keys(selectedProperties).filter(key => selectedProperties[key]);

          return data.map(item => {
            const filteredItem = {};
            keysToKeep.forEach(key => {
              if (item.hasOwnProperty(key)) {
                filteredItem[key] = item[key];
              }
            });
            return filteredItem;
          });
        };

        setExpenseReportFilteredData(filterData(data.expenses, selectedPropertiesForExpense));
      } else {
        const filteredData = data.expenses.filter((item) => item.Status === downloadFilter)

        const filterData = (data, selectedProperties) => {
          const keysToKeep = Object.keys(selectedProperties).filter(key => selectedProperties[key]);

          return data.map(item => {
            const filteredItem = {};
            keysToKeep.forEach(key => {
              if (item.hasOwnProperty(key)) {
                filteredItem[key] = item[key];
              }
            });
            return filteredItem;
          });
        };
        setExpenseReportFilteredData(filterData(filteredData, selectedPropertiesForExpense));
      }
    } catch (error) {
      console.log(error)
    }
  }
  const getCompanyName = async () => {
    try {
      const { data } = await axios.get(`https://app.timetraker.com/backend/api/company-name/?companyID=${companyID}`, config)
      // allTheEmployee.push(...data.allSubAdmin)
      console.log("company", data)
      setCompanyName(data.company.companyName)
    } catch (error) {
      console.log("error from get all user api", error)
    }
  }


  useEffect(() => {
    getAllUserExpense()
    getAllChartOfAccount()
    getAllProject()
    getAllSubAdmin()
    getAllManager()
    getAllUser()
    getSuperAdmin()
    getCompanyName()
    if (!forPhoneScreenNoDisplay) {
      setSideBarStatus(false)
    }
  }, [])



  const propertyLabels = {
    Date: "Date",
    Expense: "Expense",
    Project: "Project",
    Amount: "Amount",
    Status: "Status",
    Employee: "Employee",
    Billable: "Billable",
    Description: "Description",
    Location: "Location",
  };

  const columnData = Object.entries(selectedPropertiesForExpense)
    .filter(([key, value]) => value)
    .map(([key]) => ({
      id: key,
      label: propertyLabels[key] || key,
      minWidth: key === "Date" ? 130 : 100,
    }));

  return (
    <AdminLayout>

      <div className={sideBarStatus ? 'content_right_dashboard' : "none"} fluid style={sideBarStatus == true & forPhoneScreenNoDisplay == false ? { display: 'none' } : { display: 'block' }}>
        <Container fluid className="dash3" style={{ padding: "0", minHeight: '100vh' }}>
          {/* Row 1 */}
          <Row>
            <Col className='task-container' >
              <div className="hamburgar" style={sideBarStatus ? { display: 'none' } : { display: 'block' }}>
                <i onClick={handleClick} className='fas fa-bars'></i>
              </div>
              <div className="task-top">
                <div className="task-header1">
                  <h5 style={
                    sideBarStatus == true
                      ? { paddingLeft: "10px", paddingTop: "2px" }
                      : { paddingLeft: "60px", paddingTop: "4px" }
                  } >Expense Reports</h5>
                </div>

                <div className="profile-header2">
                  <ProfileComponent />
                </div>
              </div>
            </Col>
          </Row>
          <div style={{ padding: '20px' }}>
            <Grid container>

              <Grid className='left_grid' item xs={12} lg={5}>
                <div>
                  <p className='left_box_heading'>Select Columns</p>
                  <ExpenseFilter allUserExpenseInfo={allUserExpenseInfo} />
                </div>

              </Grid>

              <Grid className='right_grid' item xs={12} lg={6.5}>

                <form onSubmit={handleSubmit}>
                  <p className='left_box_heading'>Expenses Summary</p>
                  <Grid container>
                    <Grid item xs={12} md={6} lg={6} className='right_selact'>
                      <div className='date_duration'>
                        <label className='lable_bold' htmlFor="">Start Date</label>
                        <input style={{ cursor: 'pointer' }} className='date' name='startDate' onChange={handleChange} type='date' />
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6} className='right_selact'>
                      <div className='date_duration'>
                        <label className='lable_bold' htmlFor="">End Date</label>
                        <input style={{ cursor: 'pointer' }} className='date' name='endDate' onChange={handleChange} type='date' />
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6} className='right_selact'>
                      <label className='lable_bold' htmlFor="">Employee</label>
                      <Form.Select
                        style={{ cursor: 'pointer' }}
                        className="area"
                        name="userName"
                        aria-label="Default select example"
                        onChange={handleChange}
                      >
                        <option value=''>Select</option>
                        {allSubAdmin &&
                          allSubAdmin.map((item, index) => {
                            return (
                              <option key={index} value={item.name}>
                                {item.name}
                              </option>
                            );
                          })}
                        {allManager &&
                          allManager.map((item, index) => {
                            return (
                              <option key={index} value={item.name}>
                                {item.name}
                              </option>
                            );
                          })}
                        {allUser &&
                          allUser.map((item, index) => {
                            return (
                              <option key={index} value={item.name}>
                                {item.name}
                              </option>
                            );
                          })}
                        {allSuperAdmin &&
                          allSuperAdmin.map((item, index) => {
                            return (
                              <option key={index} value={item.name}>
                                {item.name}
                              </option>
                            );
                          })}
                      </Form.Select>
                    </Grid>


                    <Grid item xs={12} md={6} lg={6} className='right_selact'>
                      <label className='lable_bold' htmlFor="">Project</label>
                      <Form.Select
                        style={{ cursor: 'pointer' }}
                        className="area"
                        name="project"
                        aria-label="Default select example"

                        onChange={handleChange}
                      >
                        <option value=''>Select</option>
                        {projectInfo &&
                          projectInfo.map((item, index) => {
                            return (
                              <option key={index} value={item.project}>
                                {item.project}
                              </option>
                            );
                          })}
                      </Form.Select>
                    </Grid>


                    <Grid item xs={12} md={6} lg={6} className='right_selact'>
                      <label className='lable_bold' htmlFor="">Type</label>
                      <Form.Select
                        style={{ cursor: 'pointer' }}
                        className="area"
                        name="expense"
                        aria-label="Default select example"
                        onChange={handleChange}
                      >
                        <option value=''>Select</option>
                        {chartOfAccount &&
                          chartOfAccount.map((item, index) => {
                            return (
                              <option key={index} value={item.name}>
                                {item.name}
                              </option>
                            );
                          })}
                      </Form.Select>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6} className='right_selact'>
                      <label className='lable_bold' htmlFor="">Billable</label>
                      <Form.Select
                        style={{ cursor: 'pointer' }}
                        className="area"
                        name="billable"
                        aria-label="Default select example"
                        onChange={handleChange}
                      >
                        <option value=''>Select</option>
                        <option value={true} >
                          Yes
                        </option>
                        <option value={false} >
                          No
                        </option>
                      </Form.Select>
                    </Grid>

                    <Grid item xs={12} md={6} lg={6} className='right_selact'>
                      <label className='lable_bold' htmlFor=""> Status</label>
                      <Form.Select
                        style={{ cursor: 'pointer' }}
                        className="area"
                        name="Downloded"
                        aria-label="Default select example"
                        onChange={(e) => { setDownloadFilter(e.target.value) }}
                      >
                        <option value=''>
                          Select
                        </option>
                        <option value="hold" >
                          On Hold
                        </option>
                        <option value="submited" >
                          Submitted
                        </option>
                        <option value="pending download" >
                          Pending Download
                        </option>
                        <option value="downloaded" >
                          Downloaded
                        </option>
                      </Form.Select>
                    </Grid>
                  </Grid>
                  <div className='right_button'>
                    <button style={{ padding: "0px 40px" }} className="csv_button">Filter</button>
                  </div>
                </form>
              </Grid>
            </Grid>



          </div>

        </Container>
      </div>


      <Dialog
        open={open}
        TransitionComponent={Transition}
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="lg"

      >
        <div style={{ padding: "0px 20px 20px 20px" }}>
          <div style={{ margin: '30px 0px' }} className='left_box_buttons'>
            <ExpenseExcel companyName={companyName} />
            <ExpensePdf companyName={companyName} />
            <ExpenseCsv companyName={companyName} />
          </div>
          <Paper sx={{ width: "100%", overflow: "auto" }}>
            <TableContainer>
              <Table
                stickyHeader
                aria-label="sticky table"
                style={{ overflow: "visible", cursor: "pointer" }}
              >
                <TableHead>
                  <TableRow>
                    {columnData.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{
                          minWidth: column.minWidth,
                          background: "#04542C",
                          color: "#fff",
                          textAlign: "center",
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody
                  style={{ overflow: "hidden", cursor: "pointer" }}
                >
                  {expenseReportFilteredData?.map((item, index) => {

                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                        {columnData.map((column) => (
                          <TableCell key={column.id} style={{ textAlign: 'center' }}>
                            {column.id === 'Date' && item.Date}
                            {column.id === 'Expense' && item.Expense}
                            {column.id === 'Project' && item.Project}
                            {column.id === 'Amount' && item.Amount}
                            {column.id === 'Status' && item.Status}
                            {column.id === 'Employee' && item.Employee}
                            {column.id === 'Billable' && (item.Billable ? "Yes" : "No")}
                            {column.id === 'Downloaded' && (item.Downloaded ? "Yes" : "No")}
                            {column.id === 'Description' && (item.Description ? item.Description : "Empty")}
                            {column.id === 'Location' && (item.Location ? item.Location : "Empty")}
                          </TableCell>
                        ))}


                      </TableRow>
                    );
                  })}

                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </div>
      </Dialog>
    </AdminLayout>
  )
}

export default ExpenseReport
