import React from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

const MapComponent = ({ locations }) => {
  const mapStyles = {
    height: '400px',
    width: '100%'
  };

  const defaultCenter = {
    lat: locations.length ? locations[0].lat : 0,
    lng: locations.length ? locations[0].lng : 0
  };

  return (
    <LoadScript googleMapsApiKey="AIzaSyBQwpp2897AHVB0tI-sE5LRGfDwE_iHnGQ">
      <GoogleMap
        mapContainerStyle={mapStyles}
        zoom={13}
        center={defaultCenter}
      >
        {locations.map((location, index) => (
          <Marker key={index} position={{ lat: location.lat, lng: location.lng }} />
        ))}
      </GoogleMap>
    </LoadScript>
  );
}

export default MapComponent;
