import React, { useState, useEffect, useContext } from "react";
import "./Task.css";
import "./Assign.css"
import { Col, Container, Row, Button, Form } from "react-bootstrap";
import axios from "axios";
import AdminLayout from "../../hoc/AdminLayout";
import AssignProjectTable from "../../components/AssignProjectTable";
import { MyContext } from "../../context/MyProvider";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MultiSelect from "../../components/MultiSelect";
import ProfileComponent from "../../components/ProfileComponent";



const Assign = () => {
    const navigate = useNavigate();

    const { sideBarStatus, setSideBarStatus } = useContext(MyContext);

    const handleClick = () => {
        if (sideBarStatus == true) {
            setSideBarStatus(false);
        } else {
            setSideBarStatus(true);
        }
    };

    const screenWidth = window.innerWidth;
    if (screenWidth >= 840) {
        var forPhoneScreenNoDisplay = true;
    } else {
        var forPhoneScreenNoDisplay = false;
    }

    const adminloginInfo = JSON.parse(localStorage.getItem("adminLoginInfo"));
    const userLoginInfo = JSON.parse(localStorage.getItem("userLoginInfo"));
    if (adminloginInfo) {
        var companyID = adminloginInfo?._id;
        var token = adminloginInfo?.token
    }
    if (userLoginInfo) {
        var companyID = userLoginInfo?.companyID;
        var token = userLoginInfo?.token
    }

    const [formData, setFromData] = useState({ companyID: companyID });
    const [projects, setProjects] = useState([]);
    const [tasks, setTasks] = useState([]);
    const [teams, setTeams] = useState([]);
    const [role, setRole] = useState(null);
    const [user, setUser] = useState([]);
    const [open, setOpen] = useState(false);

    const [projectInfo, setProjectInfo] = useState([]);
    const [filteredAssign, setfilteredAssign] = useState([]);
    const [filter, setFilter] = useState("select");
    const [dataPrint, setDataPrint] = useState([]);
    const [reset, setReset] = useState(false)

    // config

    console.log("projectInfo", projectInfo)

    useEffect(() => {
        const ProjectFunc = async () => {
            try {

                const [response1] = await Promise.all([
                    axios.get(`https://app.timetraker.com/backend/api/all-project-with-assign/?companyID=${companyID}`, config),
                ]);


                const combinedArray = [...response1.data.projects];
                const combinedArray2 = [...response1.data.assigned ];

                setProjects(combinedArray);
                setProjectInfo(combinedArray2)
            } catch (err) {
                console.log("error while get projects", err);
            }
        }
        ProjectFunc();
    }, []);


    useEffect(() => {
        setfilteredAssign(projectInfo);
    }, [projectInfo]);

    useEffect(() => {
        if (filter === "select") {
            setfilteredAssign(projectInfo);
        }
        else if (filter === "Yes") {
            const filtered = projectInfo.filter((p) => {
                return p.Array.length > 0;
            })
            setfilteredAssign(filtered)
        }
        else {
            const filtered = projectInfo.filter((p) => {
                return p.Array.length === 0;
            })
            setfilteredAssign(filtered)
        }
    }, [filter]);

    const config = {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`
        },
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === "project") {
            const data = projects.filter((item) => item._id === value)
            if (data?.[0]?.project) {
                setFromData((prevData) => ({
                    ...prevData,
                    item: "project"
                }))
            } else {
                setFromData((prevData) => ({
                    ...prevData,
                    item: "task"
                }))
            }
        }

        setFromData((prevData) => ({
            ...prevData,
            [name]: value
        }))

        if (name === "role") {
            setRole(value);
        }
    };

    const handleMultiChange = (arr) => {
        console.log("arr1", arr);
        setFromData((prevData) => ({
            ...prevData,
            ["userArray"]: arr
        }))

    }


    const getAllUser = async () => {
        try {
            const { data } = await axios.get(
                `https://app.timetraker.com/backend/api/all-users/?companyID=${companyID}`,
                config
            );
            return data.allUsers;
        } catch (error) {
            console.log('error from get all user api', error);
            return [];
        }
    };

    const getAllManager = async () => {
        try {
            const { data } = await axios.get(
                `https://app.timetraker.com/backend/api/all-manager/?companyID=${companyID}`,
                config
            );
            return data.allManager;
        } catch (error) {
            console.log('error from get all manager api', error);
            return [];
        }
    };
    const getAllSubAdmin = async () => {
        try {
            const { data } = await axios.get(
                `https://app.timetraker.com/backend/api/all-sub-admin/?companyID=${companyID}`,
                config
            );
            return data.allSubAdmin;
        } catch (error) {
            console.log("error from get all user api", error);
        }
    };
    const getSuperAdmin = async () => {
        try {
            const { data } = await axios.get(
                `https://app.timetraker.com/backend/api/super-admin/?companyID=${companyID}`,
                config
            );
            return data.superAdmin;
        } catch (error) {
            console.log("error from get all user api", error);
        }
    };

    const fetchData = async () => {
        const [users, managers, subAdmins, superAdmin] = await Promise.all([getAllUser(), getAllManager(), getAllSubAdmin(), getSuperAdmin()]);
        return [...users, ...managers, ...subAdmins, ...superAdmin]
    };


    useEffect(() => {
        const getUserViaRole = async () => {
            const data = await fetchData()
            setUser(data);
        }
        getUserViaRole();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {

            if (formData.item === "project") {
                const { data } = await axios.post(
                    "https://app.timetraker.com/backend/api/assign-project",
                    {
                        companyID: formData.companyID,
                        userArray: formData.userArray,
                        project: formData.project,
                        type: formData.type
                    },
                    config
                );

            } else {
                const { data } = await axios.post(
                    "https://app.timetraker.com/backend/api/assign-task",
                    {
                        companyID: formData.companyID,
                        userArray: formData.userArray,
                        task: formData.project,
                        type: formData.type
                    },
                    config
                );
            }
            setFromData({
                companyID: formData.companyID,
                project: "",
                role: "",
                userArray: []
            })
            handleClose();
            getAllProject();
            setReset(true)
            document.getElementById("crateForm").reset()
            TimesheetSubmitNotfy()
        } catch (error) {
            console.log("error from create Project Api", error);
        }
    };

    const getAllProject = async () => {
        try {

            const [response1] = await Promise.all([
                axios.get(`https://app.timetraker.com/backend/api/all-project-with-assign/?companyID=${companyID}`, config),
            ]);


            const combinedArray = [...response1.data.projects];
            const combinedArray2 = [...response1.data.assigned];

            setProjects(combinedArray);
            setProjectInfo(combinedArray2)
        } catch (err) {
            console.log("error while get projects", err);
        }
    };

    const getAllTeam = async () => {
        try {
            const { data } = await axios.get(
                `https://app.timetraker.com/backend/api/all-team/?companyID=${companyID}`,
                config
            );
            setTeams(data);
        } catch (error) {
            console.log("error from team api", error);
        }
    };





    useEffect(() => {
        getAllProject();
        getAllTeam();
        if (!forPhoneScreenNoDisplay) {
            setSideBarStatus(false);
        }
    }, []);


    useEffect(() => {
        if (filter === "Yes" || filter === "select") {
            const data = filteredAssign.map((p) => {
                const project = p.project;
                return p.Array.map((user) => ({ project: project?.project ? project?.project : project?.task, name: user.name, email: user.email, role: user.role }))
            })
            setDataPrint(data);
        } else {
            const data = filteredAssign.map((p) => {
                return p.project;
            })
            setDataPrint(data);
        }

    }, [filteredAssign]);


    const TimesheetSubmitNotfy = () => {
        toast.success(' Item has been assigned', {
            position: 'top-right',
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    };

    const handleClose = () => {
        setOpen(false);
    }


    return (
        <AdminLayout>
            <ToastContainer />
            <div
                className={sideBarStatus ? "content_right_dashboard_2" : "none"}
                fluid
                style={
                    (sideBarStatus == true) & (forPhoneScreenNoDisplay == false)
                        ? { display: "none" }
                        : { display: "block", minHeight: "100vh", background: "#f1f1f1" }
                }
            >
                <Container fluid className="dash3" style={{ padding: "0" }}>
                    {/* Row 1 */}
                    <Row>
                        <Col className="task-container">
                            <div
                                className="hamburgar"
                                style={
                                    sideBarStatus ? { display: "none" } : { display: "block" }
                                }
                            >
                                <i onClick={handleClick} className="fas fa-bars"></i>
                            </div>
                            <div className="task-top">
                                <div className="task-header1">
                                    <h5
                                        style={
                                            sideBarStatus == true
                                                ? { paddingLeft: "10px", paddingTop: "2px" }
                                                : { paddingLeft: "60px", paddingTop: "4px" }
                                        }
                                    >
                                        Assign
                                    </h5>
                                </div>

                                <div className="profile-header2">
                                    <ProfileComponent />
                                </div>
                            </div>
                        </Col>
                    </Row>



                    <Row style={{ background: "#F1F1F1" }} className="mt-0">
                        <Col>
                            <div
                                className="time"
                                style={{
                                    display: "flex",
                                    justifyContent: "start",
                                    alignItems: "center",
                                    flexWrap: "wrap",
                                    marginLeft: "84px"
                                }}
                            >

                                <div className="text-time">
                                    <p
                                        style={{ cursor: "pointer", marginBottom: "0px" }}
                                        className="para1"
                                        onClick={() => {
                                            navigate("/project");
                                        }}
                                    >
                                        Project
                                    </p>

                                    <p
                                        style={{ cursor: "pointer", marginBottom: "0px" }}
                                        className="para2"
                                        onClick={() => {
                                            navigate("/task");
                                        }}
                                    >
                                        Task
                                    </p>

                                    <p
                                        style={{ backgroundColor: "#64E48E", cursor: "pointer", marginBottom: "0px" }}
                                        className="para1"
                                    >
                                        Assign
                                    </p>

                                </div>
                            </div>
                        </Col>
                    </Row>




                    <Row style={{ background: "#F1F1F1" }}>
                        <Col md={12}>
                            <div className="adduser peoject_form_padding" style={{ marginBottom: "27px" }}>
                                <div className="adduser-header project-header">
                                    <button style={{ width: "182px" }} className="btn1">Assign Info</button>
                                </div>


                                <form
                                    onSubmit={handleSubmit}
                                    action=""
                                    className="adduser-form"
                                    id="crateForm"
                                    style={{ flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: "20px" }}
                                >

                                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                                            <div className="form1 ">
                                                <div style={{ marginBottom: "2rem" }} className=" projectInput">
                                                    <label className="lable_bold" htmlFor="">
                                                        Name
                                                        <i className="fa-sharp fa-light fa-asterisk"></i>
                                                    </label>
                                                    <Form.Select
                                                        required
                                                        onChange={handleChange}
                                                        value={formData.project}
                                                        name="project"
                                                        className="project_select"
                                                        aria-label="Default select example"
                                                        style={{ marginLeft: "0px" }}
                                                    >
                                                        <option value="">Select</option>
                                                        {projects?.map((p) => (
                                                            <option value={p._id}>{p.project ? p.project : p.task}</option>
                                                        ))}
                                                    </Form.Select>
                                                </div>


                                                <div style={{ marginBottom: "2rem" }} className="role-border projectInput">
                                                    <label className="lable_bold" htmlFor="">
                                                        Type
                                                        <i className="fa-sharp fa-light fa-asterisk"></i>
                                                    </label>
                                                    <Form.Select
                                                        required
                                                        onChange={handleChange}
                                                        value={formData.type}
                                                        name="type"
                                                        className="project_select"
                                                        aria-label="Default select example"
                                                        style={{ marginLeft: "0px" }}
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="individual">Individual</option>
                                                        <option value="team">Team</option>
                                                    </Form.Select>

                                                </div>



                                            </div>

                                            <div className="form2">


                                                <div style={{ marginBottom: "2rem" }} className="role-border projectInput">
                                                    <label className="lable_bold" htmlFor="">
                                                        Team
                                                        {
                                                            formData.type === 'team' ?
                                                                <i className="fa-sharp fa-light fa-asterisk"></i>
                                                                :
                                                                null
                                                        }
                                                    </label>
                                                    <Form.Select
                                                        onChange={handleChange}
                                                        disabled={formData.type === 'team' ? false : true}
                                                        required={formData.type === 'team' ? true : false}
                                                        name="team"
                                                        className="project_select"
                                                        aria-label="Default select example"
                                                        style={{ marginLeft: "0px" }}
                                                    >
                                                        <option value="">Select</option>
                                                        {teams?.map((t) => (
                                                            <option value={t.members}>{t.name}</option>
                                                        ))}
                                                    </Form.Select>


                                                </div>

                                                <div style={{ width: "100%", marginBottom: "2rem" }} className=" projectInput">
                                                    <label className="lable_bold" htmlFor="">
                                                        Employee
                                                        {
                                                            formData.type === 'individual' ?
                                                                <i className="fa-sharp fa-light fa-asterisk"></i>
                                                                :
                                                                null
                                                        }
                                                    </label>
                                                    <MultiSelect disable={formData.type === 'individual' ? false : true} user={user} handleMultiChange={handleMultiChange} reset={reset} setReset={setReset} />
                                                </div>

                                            </div>
                                        </div>



                                    </div>

                                    <div className="adduserBtn ">
                                        <button type="submit" className="btn5">
                                            Assign
                                        </button>
                                    </div>

                                </form>
                            </div>
                        </Col>
                    </Row>





                    {/* Row 3 */}
                    <Row style={{ background: "#F1F1F1" }}>
                        <Col>
                            <div className="table-container">
                                <AssignProjectTable
                                    projectInfo={filteredAssign}
                                    setProjectInfo={setProjectInfo}
                                    getAllProject={getAllProject}
                                    user={user}

                                />
                            </div>
                        </Col>
                    </Row>


                </Container>
            </div>
        </AdminLayout>
    );
};

export default Assign;