import React, { useEffect, useContext } from "react";
import "./TimesheetAprove.css";
import AdminLayout from "../../hoc/AdminLayout";
import { Col, Container, Row, Form } from "react-bootstrap";
import { MyContext } from "../../context/MyProvider";
import ProfileComponent from "../../components/ProfileComponent";
import TimesheetAndTimeOffApprove from "../../components/TimesheetApproveComponent/TimesheetAndTimeOffApprove";




const TimesheetAprove = () => {
  const { sideBarStatus, setSideBarStatus } = useContext(MyContext);

  const handleClick = () => {
    if (sideBarStatus == true) {
      setSideBarStatus(false);
    } else {
      setSideBarStatus(true);
    }
  };

  const screenWidth = window.innerWidth;
  if (screenWidth >= 840) {
    var forPhoneScreenNoDisplay = true;
  } else {
    var forPhoneScreenNoDisplay = false;
  }

  const { loading, setLoading, loadingTimesheet, setLoadingTimesheet } = useContext(MyContext);






  return (
    <AdminLayout>


      <div
        className={sideBarStatus ? "content_right_dashboard" : "none"}
        style={
          (sideBarStatus == true) & (forPhoneScreenNoDisplay == false)
            ? { display: "none" }
            : loading || loadingTimesheet ?
            { filter: 'blur(2px)', display: "block", minHeight: "100vh", background: "#f1f1f1" }
            : { display: "block", minHeight: "100vh", background: "#f1f1f1" }
        }
      >
        <Row>
          <div
            className="hamburgar"
            style={sideBarStatus ? { display: "none" } : { display: "block" }}
          >
            <i onClick={handleClick} className="fas fa-bars"></i>
          </div>
          <Col md={12} className="dash-container3">
            <div className="header-top3">
              <div className="dash-header1">
                <h5 style={
                  sideBarStatus == true
                    ? { paddingLeft: "10px", paddingTop: "2px" }
                    : { paddingLeft: "60px", paddingTop: "4px" }
                }>
                  Timesheets
                </h5>
              </div>

              <div className="profile-header2">
                <ProfileComponent />
              </div>
            </div>
          </Col>
        </Row>

        <TimesheetAndTimeOffApprove timeApprove={true} />
      </div>


    </AdminLayout>
  );
};

export default TimesheetAprove;
