import React, { useState, useEffect } from "react";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import axios from 'axios';
import "./TableDesign.css"
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import Form from "react-bootstrap/Form";
import Checkbox from '@mui/material/Checkbox';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import { blue } from '@mui/material/colors';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import InfoIcon from '@mui/icons-material/Info';
import { Typography } from "@mui/material";
import UserPdf from "../pages/admin/UserPdf";
import UserCsv from "../pages/admin/UserCsv";
import UserExcel from "../pages/admin/UserExcel";
import Swal from 'sweetalert2'
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Select from '@mui/material/Select';
import { MenuProps, useStyles } from "../pages/admin/Timeoff/utils";

const columns = [
    { id: 'date', label: 'Date', minWidth: 150 },
    { id: 'name', label: 'Name', minWidth: 100 },
    { id: 'Enrolled', label: 'Enrolled', minWidth: 100 },
    { id: 'occursYearly', label: 'Occurs Yearly', minWidth: 100 },

    { id: 'more', label: 'More', minWidth: 100 },
];


// temporory data

const data = [
    {
        task: 'Development',
        status: 'Active',
        createdAt: '2023-06-07T04:12:17.986Z'
    },
    {
        task: 'Development',
        status: 'Active',
        createdAt: '2023-06-07T04:12:17.986Z'
    },
    {
        task: 'Development',
        status: 'Active',
        createdAt: '2023-06-07T04:12:17.986Z'
    },

];

export default function StickyHeadTable({ holidayInfo, handleDataFromChild, setHolidayInfo, allemployee }) {

    const adminloginInfo = JSON.parse(localStorage.getItem("adminLoginInfo"));
    const userLoginInfo = JSON.parse(localStorage.getItem("userLoginInfo"));
    if (adminloginInfo) {
        var companyID = adminloginInfo?._id;
        var token = adminloginInfo?.token
        var userID = adminloginInfo._id;
    }
    if (userLoginInfo) {
        var companyID = userLoginInfo?.companyID;
        var token = userLoginInfo?.token
        var userID = userLoginInfo._id;
    }

    const [projectInfo, setProjectInfo] = useState([]);
    const [taskInfo, setTaskInfo] = useState([]);
    const [selactedTask, setSelactedTask] = useState("")
    const [selactedBillable, setSelactedBillable] = useState(true)
    const [selactedProject, setSelactedProject] = useState("")
    const [formData, setFormData] = useState({
        userID: userID,
        companyID: companyID,
        date: "",
        hours: "",
        project: "",
        task: "",
    });

    const classes = useStyles();
    const [selected, setSelected] = useState([]);
    const isAllSelected =
        allemployee.length > 0 && selected.length === allemployee.length;


    //config 

    const config = {
        headers: {
            Accept: 'application/json',
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    };



    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const [moreIconData, setMoreIconData] = useState()

    const handleOpenUserMenu = (event, item) => {
        setAnchorElUser(event.currentTarget);
        setMoreIconData(item)
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const [anchorElUser1, setAnchorElUser1] = React.useState(null);
    const [rejectReason, setRejectReason] = useState("")

    const handleOpenUserMenu1 = (event, data) => {
        setAnchorElUser1(event.currentTarget);
        setRejectReason(data)
    };

    const handleCloseUserMenu1 = () => {
        setAnchorElUser1(null);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };


    const handleInputChangeTask = (e) => {
        setSelactedTask(e.target.value)
    }

    const handleInputChangebillable = (e) => {
        setSelactedBillable(e.target.value)
    }

    const handleInputChangeProject = (e) => {
        setSelactedProject(e.target.value)
    }

    const handleChangeEmployee = (event) => {


        const value = event.target.value;
        if (value[value.length - 1] === "all") {
            if (selected.length === allemployee.length) {
                setSelected([]);
            } else {
                const allNames = allemployee.map(emp => emp.name);
                setSelected(allNames);
            }
            return;
        }
        setSelected(value);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));


    };

    const deleteData = async (itemID) => {
        handleCloseUserMenu()
        Swal.fire({
            title: "Are you sure?",
            text: "You want to delete this Holiday",
            // icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const { data } = await axios.delete(`https://app.timetraker.com/backend/api/delete-holiday/?holidayID=${itemID}`, config)
                    console.log(data)
                    handleDataFromChild(data)
                    TimesheetDeleteNotfy()

                } catch (error) {
                    console.log(error)
                }
            }
        })

    }



    const submitTimesheet = async (itemID) => {
        handleCloseUserMenu()
        Swal.fire({
            title: "Are you sure?",
            text: "You want to submit this Timesheet",
            // icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, submit it!"
        }).then(async (result) => {
            if (result.isConfirmed) {


                try {
                    const { data } = await axios.get(`https://app.timetraker.com/backend/api/submit-timesheet/?timesheetID=${itemID}`, config)
                    console.log(data)
                    handleDataFromChild(data)
                    TimesheetSubmitNotfy()

                }
                catch (error) {
                    console.log("submitTimesheet error", error)
                }
            }
        });
    }

    const [reversSort, setReversSort] = useState(false)

    const handleChangeSort = () => {
        setReversSort(prevToggle => !prevToggle);
    };

    useEffect(() => {
        if (reversSort) {
            const data = [...holidayInfo].sort((a, b) => new Date(a.date) - new Date(b.date));
            setHolidayInfo(data)
        } else {
            const data = [...holidayInfo].sort((a, b) => new Date(b.date) - new Date(a.date));
            setHolidayInfo(data)
        }
    }, [reversSort])






    const TimesheetSubmitNotfy = () => {
        toast.success('Your timesheet has been submited', {
            position: 'top-right',
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    };


    const TimesheetDeleteNotfy = () => {
        toast.success('Your holiday has been deleted', {
            position: 'top-right',
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    };






    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };





    const handleDailogBoxOpen = async (item) => {
        // setDataNeedToEdit({
        //     _id: _id,
        //     description: description,
        //     task: task,
        //     project: project,
        //     date: date,
        //     hours: hours
        // })
        setFormData({
            _id: item._id,
            companyID: item.companyID,
            name: item.name,
            date: item.date,
            occursYearly: item.occursYearly
        })
        handleCloseUserMenu()

        handleClickOpen()
    }






    // get all project

    const getAllProject = async () => {
        try {
            const { data } = await axios.get(
                `https://app.timetraker.com/backend/api/all-project/?companyID=${companyID}`,
                config
            );
            setProjectInfo(data.projects);
        } catch (error) {
            console.log("error", error)
        }
    };


    // get all task

    const getAllTask = async () => {
        try {
            const { data } = await axios.get(
                `https://app.timetraker.com/backend/api/all-task/?companyID=${companyID}`,
                config
            );
            setTaskInfo(data.tasks);
        } catch (error) {
            console.log("error", error)
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const filteredEmployee = []

        selected.map((item) => {
            const filteredData = allemployee.filter((element) => element.name === item)
            if (filteredData.length > 0) {
                filteredEmployee.push({
                    userName: filteredData[0].name,
                    userID: filteredData[0]._id,
                    hireDate: filteredData[0].hireDate ? filteredData[0].hireDate : "",
                })
            }
        })
        try {
            const { data } = await axios.post(
                `https://app.timetraker.com/backend/api/edit-holiday/?companyID=${companyID}`,
                {
                    _id: formData._id,
                    companyID: formData.companyID,
                    applicableTo: filteredEmployee,
                    name: formData.name,
                    date: formData.date,
                    occursYearly: formData.occursYearly
                },
                config
            );
            handleClose();
            handleCloseUserMenu()
            if (data) {
                handleDataFromChild(data)
                successNotify(" Holiday has been updated successfully.");
            }
        } catch (error) {
            console.log("error from post timesheet api", error);
            errorNotify("Something went wrong!!")
        }
    };



    useEffect(() => {
        getAllTask();
        getAllProject();

    }, []);



    const successNotify = (message) => {
        toast.success(message, {
            position: 'top-right',
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    };

    const errorNotify = (message) => {
        toast.error(message, {
            position: 'top-right',
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    };

    return (
        <Paper sx={{ width: '100%', overflow: 'auto' }}>
            <TableContainer className="table_overflow">
                <Table stickyHeader aria-label="sticky table">
                    <TableHead >
                        <TableRow >
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth, background: '#04542C', color: '#fff', textAlign: 'center' }}>
                                    {column.label === "Date" ?
                                        <div onClick={handleChangeSort} style={{ display: "flex", cursor: "pointer" , justifyContent: "center", marginRight: "29px"}}> <ArrowDropDownCircleIcon style={reversSort ? { transform: "rotate(180deg)", margin: "0px 10px 0px 17px" } : { margin: "0px 10px 0px 17px" }} /> {column.label} </div>
                                        : column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody >
                        {holidayInfo
                            ? (rowsPerPage > 0 ? holidayInfo.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : holidayInfo)?.map((item, index) => {
                                function shortenString(str) {
                                    if (str?.length > 8) {
                                        return str.substring(0, 8) + '...';
                                    }
                                    return str;
                                }

                                const Enrolled = item?.applicableTo?.length ? item?.applicableTo?.length : 0


                                return (

                                    <TableRow style={{ background: "white" }} hover role="checkbox" tabIndex={-1} key={index}>
                                        <TableCell align="left">{item?.date}</TableCell>
                                        <TableCell align="center">{item?.name}</TableCell>
                                        <TableCell align="center">{Enrolled}</TableCell>
                                        <TableCell align="center">{item.occursYearly ? "Yes" : "No"}</TableCell>


                                        <TableCell onClick={(event) => handleOpenUserMenu(event, item)} style={{ cursor: "pointer" }} align="center"><MoreHorizIcon style={{
                                            background: "#e3e3e3d1",
                                            borderRadius: "100px",
                                            height: "22px",
                                            width: "45px"
                                        }} /></TableCell>
                                        <Menu
                                            sx={{ mt: '45px' }}
                                            id="menu-appbar"
                                            anchorEl={anchorElUser}
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            open={Boolean(anchorElUser)}
                                            onClose={handleCloseUserMenu}
                                        >
                                            <MenuItem style={{ gap: "10px", alignItems: "start", background: "white" }} >



                                                <TableCell style={{ borderBottom: "none", borderRight: "1px solid #dddddd" }} align="center">
                                                    <i
                                                        title="Edit"
                                                        onClick={() => handleDailogBoxOpen(
                                                            moreIconData
                                                        )

                                                        }
                                                        style={{ color: 'green', paddingLeft: '0px', fontSize: '20px', cursor: 'pointer' }

                                                        }
                                                        className="fa-solid fa-pen-to-square userIconsHover"
                                                    ></i>
                                                </TableCell>

                                                <TableCell style={{ borderBottom: "none" }} align="center">
                                                    <i
                                                        title="Delete"
                                                        onClick={() =>
                                                            deleteData(moreIconData?._id)

                                                        }
                                                        style={{ color: 'red', fontSize: '20px', cursor: 'pointer' }
                                                        }
                                                        className="fa-sharp fa-solid fa-trash userIconsHover"
                                                    ></i>
                                                </TableCell>

                                            </MenuItem>
                                        </Menu>

                                    </TableRow>
                                )


                            })
                            : data?.map((item, index) => {
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                        <TableCell align="center">{item?.hours}</TableCell>
                                        <TableCell align="center">{item?.date}</TableCell>
                                        <TableCell align="center">{item?.project}</TableCell>
                                        <TableCell align="center">{item?.task}</TableCell>
                                        <TableCell align="center">{item?.state}</TableCell>
                                        <TableCell align="center">{item?.createdAt}</TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                component="div"
                count={holidayInfo?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />




            <Dialog
                open={open}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <div
                    className="dialog-main"
                    style={{height:"373px"}}
                >
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "end",
                            alignItems: "center",
                        }}
                    >
                        <DialogActions>
                            <Button
                                style={{

                                    background: "rgb(100, 228, 142)",
                                    borderRadius: "10px",
                                    color: "#000",
                                    fontWeight: "600",
                                    padding: "5px 15px",
                                }}
                                onClick={handleClose}
                            >
                                Close
                            </Button>
                        </DialogActions>
                    </div>

                    <div className="border-around">
                        <div style={{ display: "flex" }} className="form-time1 ">
                            <div style={{ width: "50%", display: 'flex', flexDirection: 'column', gap: '5px' }} className="customer">
                                <label className="lable_bold" htmlFor="">
                                    Holiday Name
                                    <i className="fa-sharp fa-light fa-asterisk"></i>
                                </label>
                                <input
                                    style={{ height: '40px' }}
                                    required
                                    value={formData.name}
                                    onChange={handleChange}
                                    type="text"
                                    name="name"
                                    id=""
                                />
                            </div>

                            <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', width: "50%" }} className="task">
                                <label className="lable_bold" htmlFor="">

                                    Employee
                                </label>
                                <Select


                                    className="area"
                                    labelId="mutiple-select-label"
                                    multiple
                                    onChange={handleChangeEmployee}
                                    value={selected}
                                    renderValue={(selected) => selected.join(", ")}
                                    MenuProps={MenuProps}
                                >

                                    <MenuItem
                                        value="all"
                                        classes={{
                                            root: isAllSelected ? classes.selectedAll : ""
                                        }}
                                    >
                                        <ListItemIcon>
                                            <Checkbox
                                                classes={{ indeterminate: classes.indeterminateColor }}
                                                checked={isAllSelected}
                                                indeterminate={
                                                    selected.length > 0 && selected.length < allemployee.length
                                                }
                                            />
                                        </ListItemIcon>
                                        <ListItemText
                                            classes={{ primary: classes.selectAllText }}
                                            primary="Select All"
                                        />
                                    </MenuItem>
                                    {allemployee.map((item, index) => (

                                        <MenuItem key={item.name} value={item.name}>
                                            <ListItemIcon>
                                                <Checkbox checked={selected.indexOf(item.name) > -1} />
                                            </ListItemIcon>
                                            <ListItemText primary={item.name} />
                                        </MenuItem>
                                    ))}
                                </Select>

                            </div>
                        </div>

                        <div style={{ display: "flex" }} className="form-time1 ">
                            <div style={{ width: "50%", display :"flex", flexDirection :"column" }} className="customer">
                                <label className="lable_bold" htmlFor="">
                                    Date
                                    <i className="fa-sharp fa-light fa-asterisk"></i>
                                </label>

                                <input
                                    style={{ height: '40px' }}
                                    required
                                    value={formData.date}
                                    onChange={handleChange}
                                    type="date"
                                    name="date"
                                    id=""
                                />
                            </div>

                            <div style={{ width: "50%" }} className="task">
                                <label className="lable_bold" htmlFor="">
                                    Occurs Yearly
                                    <i className="fa-sharp fa-light fa-asterisk"></i>
                                </label>
                                <Form.Select
                                    required
                                    onChange={handleChange}
                                    name="occursYearly"
                                    className="area"
                                    aria-label="Default select example"
                                    value={formData.occursYearly}
                                >
                                    <option value="">Select</option>
                                    <option value={true}>Yes </option>
                                    <option value={false}>No</option>
                                </Form.Select>
                            </div>
                        </div>








                        <div className="strong">
                            <strong onClick={handleSubmit} style={{ display: "flex" }}>
                                Save
                            </strong>
                        </div>
                    </div>
                </div>
            </Dialog>

        </Paper>
    );
}
