import React, { useState } from 'react'
import "../../components/Faq.css"
import Navbar from '../../components/Navbar'
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import formlogo from "../../img/TimeTrakerLogo.png"
import "../../LandingAllPages/ContactSection.css";
import { useNavigate } from 'react-router-dom';
import Footer from '../../components/Footer';
import Footer2 from '../../components/Footer2';



const NeedAssistance = () => {
    const [toggle1, setToggle1] = useState(true)
    const [toggle2, setToggle2] = useState(true)
    const [toggle3, setToggle3] = useState(true)
    const [toggle4, setToggle4] = useState(true)
    const [toggle5, setToggle5] = useState(true)
    const [toggle6, setToggle6] = useState(true)
    const [toggle7, setToggle7] = useState(true)
    const [toggle8, setToggle8] = useState(true)
    const [toggle9, setToggle9] = useState(true)

    const handleToggle1 = () => {
        setToggle1(!toggle1)
    }
    const handleToggle2 = () => {
        setToggle2(!toggle2)
    }
    const handleToggle3 = () => {
        setToggle3(!toggle3)
    }
    const handleToggle4 = () => {
        setToggle4(!toggle4)
    }
    const handleToggle5 = () => {
        setToggle5(!toggle5)
    }
    const handleToggle6 = () => {
        setToggle6(!toggle6)
    }
    const handleToggle7 = () => {
        setToggle7(!toggle7)
    }
    const handleToggle8 = () => {
        setToggle8(!toggle8)
    }
    const handleToggle9 = () => {
        setToggle9(!toggle9)
    }

    const navigate = useNavigate()
    const [currentDateTime, setCurrentDateTime] = useState(new Date());


    //config 

    const config = {
        headers: {
            Accept: 'application/json',
            'Content-type': 'application/json',
        }
    };


    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        companyName: "",
        phoneNumber: "",
        message: "",
        currentDateTime: currentDateTime,
        needAssistance: true
    });



    const [errors, setErrors] = useState({});

    const validateForm = () => {
        let valid = true;
        const newErrors = {};

        // Validate First Name
        if (!formData.firstName.trim()) {
            newErrors.firstName = "First Name is required";
            valid = false;
        }

        // Validate Last Name
        if (!formData.lastName.trim()) {
            newErrors.lastName = "Last Name is required";
            valid = false;
        }

        // Validate Email
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!formData.email.trim() || !emailRegex.test(formData.email)) {
            newErrors.email = "Invalid Email address";
            valid = false;
        }

        // Validate Company Name
        if (!formData.companyName.trim()) {
            newErrors.companyName = "Company Name is required";
            valid = false;
        }

        // Validate Phone Number
        const phoneRegex = /^\d{10}$/;
        if (
            !formData.phoneNumber.trim() ||
            !phoneRegex.test(formData.phoneNumber)
        ) {
            newErrors.phoneNumber = "Invalid Phone Number (10 digits)";
            valid = false;
        }

        // Validate Message
        if (!formData.message.trim()) {
            newErrors.message = "Message is required";
            valid = false;
        }

        setErrors(newErrors);
        return valid;
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // emailjs.sendForm('service_rfyf6q5', 'template_eb8gf3j', form.current, 'co_XHnyZaa5HHJ609')
        //   .then((result) => {
        //       console.log(result.text);
        //   }, (error) => {
        //       console.log(error.text);
        //   });

        // Validate the form before submission
        if (validateForm()) {
            // Process the form data
            contactSaveInfo()
            saveContactInfo()
        } else {
            // alert("Please fill the form");
        }


    };

    const saveContactInfo = async () => {
        try {
            const { data } = await axios.post(
                "https://app.timetraker.com/backend/api/create-new-contact",
                formData,
                config
            );
            setFormData(
                {
                    firstName: "",
                    lastName: "",
                    email: "",
                    companyName: "",
                    phoneNumber: "",
                    message: "",
                }
            )
        } catch (error) {
            console.log("error from create Project Api", error);
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const contactSaveInfo = () => {
        toast.success('Thank you for your message. We will contact you soon.', {
            position: 'top-right',
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    };

    return (
        <div>
            <div className="contactNavbar" >
                <Navbar />
            </div>
            <ToastContainer />
            <div className='mainContainer1' id='faq' >
                <div style={{ display: 'flex' }}>
                    <h4 >Frequently Asked Questions (FAQ’s)</h4>
                </div>

                <div className='headContainer1' onClick={handleToggle1} >
                    <div className='headIcon1'>
                        <i onClick={handleToggle1} class={toggle1 ? "fa-solid fa-plus" : "fa-solid fa-minus"}></i>
                        <h1 onClick={handleToggle1}>What is a "<span style={{ color: "#04542C" }}>TIMETRAKER</span>", and how does it work?</h1>
                    </div>
                    <div className='para1' style={{ display: toggle1 ? "none" : "block" }}>
                        <p>A "<span style={{ color: "#04542C" }}>TIMETRAKER</span>" is a software tool or application that helps individuals or businesses monitor and record the time spent on various tasks and activities. It is commonly used for tracking work hours, project management, productivity analysis, and billing purposes.</p>
                    </div>
                </div>

                <div className='headContainer1' onClick={handleToggle7} >
                    <div className='headIcon1'>
                        <i onClick={handleToggle7} class={toggle7 ? "fa-solid fa-plus" : "fa-solid fa-minus"}></i>
                        <h1 onClick={handleToggle7}>How to create account in "<span style={{ color: "#04542C" }}>TIMETRAKER</span>"?</h1>
                    </div>
                    <div className='para1' style={{ display: toggle7 ? "none" : "block" }}>
                        <ul>
                            <li>
                                Go to the signup page.
                            </li>
                            <li>
                                Fill all the required details.
                            </li>
                            <li>
                                Enter the otp (You will receive the otp in the email.)
                            </li>
                            <li>
                                If you complete these steps, instantly your account will be created. Login credentials will be shared with your email.
                            </li>
                        </ul>
                    </div>
                </div>

                <div className='headContainer1' onClick={handleToggle8} >
                    <div className='headIcon1'>
                        <i onClick={handleToggle8} class={toggle8 ? "fa-solid fa-plus" : "fa-solid fa-minus"}></i>
                        <h1 onClick={handleToggle8}>How to login in "<span style={{ color: "#04542C" }}>TIMETRAKER</span>"?</h1>
                    </div>
                    <div className='para1' style={{ display: toggle8 ? "none" : "block" }}>
                        <ul>
                            <li>
                                Go to the login page.
                            </li>
                            <li>
                                Enter your email address and password.
                            </li>
                            <li>
                                Click on the login button, and you will be redirected to the dashboard page
                            </li>

                        </ul>
                    </div>
                </div>

                <div className='headContainer1' onClick={handleToggle9} >
                    <div className='headIcon1'>
                        <i onClick={handleToggle9} class={toggle9 ? "fa-solid fa-plus" : "fa-solid fa-minus"}></i>
                        <h1 onClick={handleToggle9}>How to change password in "<span style={{ color: "#04542C" }}>TIMETRAKER</span>"?</h1>
                    </div>
                    <div className='para1' style={{ display: toggle9 ? "none" : "block" }}>
                        <ul>
                            <li>
                                Go to the login page.
                            </li>
                            <li>
                                Click on the forgot password button
                            </li>
                            <li>
                                Enter your email address (This email must be linked with your TimeTraker account).
                            </li>
                            <li>
                                Enter the otp (You will receive the otp in the email.)
                            </li>
                            <li>
                                Update your password.
                            </li>

                        </ul>
                    </div>
                </div>

                <div className='headContainer1' onClick={handleToggle2}>
                    <div className='headIcon1'>
                        <i onClick={handleToggle2} class={toggle2 ? "fa-solid fa-plus" : "fa-solid fa-minus"}></i>
                        <h1 onClick={handleToggle2}>Why should I use a "<span style={{ color: "#04542C" }}>TIMETRAKER</span>" for my business or personal activities?</h1>
                    </div>
                    <div className='para1' style={{ display: toggle2 ? "none" : "block" }}>
                        <p> In both business and personal contexts, "<span style={{ color: "#04542C" }}>TIMETRAKER</span>" provides valuable data that can lead to improved decision-making, increased efficiency, and a better balance between work and personal life. It's a tool that empowers you to make the most of your time and resources.</p>
                    </div>
                </div>

                <div className='headContainer1' onClick={handleToggle3}>
                    <div className='headIcon1'>
                        <i onClick={handleToggle3} class={toggle3 ? "fa-solid fa-plus" : "fa-solid fa-minus"}></i>
                        <h1 onClick={handleToggle3}>Is it possible to track time on multiple projects or tasks simultaneously?  </h1>
                    </div>
                    <div className='para1' style={{ display: toggle3 ? "none" : "block" }}>
                        <p> Integrating a "<span style={{ color: "#04542C" }}>TIMETRAKER</span>" with other business tools can lead to increased efficiency, accuracy, and productivity. It streamlines operations, simplifies data management, and enables businesses to make data-driven decisions based on real-time insights.</p>
                    </div>
                </div>

                <div className='headContainer1' onClick={handleToggle4} >
                    <div className='headIcon1'>
                        <i onClick={handleToggle4} class={toggle4 ? "fa-solid fa-plus" : "fa-solid fa-minus"}></i>
                        <h1 onClick={handleToggle4}>What are the benefits of integrating a "<span style={{ color: "#04542C" }}>TIMETRAKER</span>" with other business tools?</h1>
                    </div>
                    <div className='para1' style={{ display: toggle4 ? "none" : "block" }}>
                        <p>yes, it is secure and private time "<span style={{ color: "#04542C" }}>TIMETRAKER</span>" software.</p>
                    </div>
                </div>

                <div className='headContainer1' onClick={handleToggle5}>
                    <div className='headIcon1'>
                        <i onClick={handleToggle5} class={toggle5 ? "fa-solid fa-plus" : "fa-solid fa-minus"}></i>
                        <h1 onClick={handleToggle5}>Is time "<span style={{ color: "#04542C" }}>TIMETRAKER</span>" software secure and private?</h1>
                    </div>
                    <div className='para1' style={{ display: toggle5 ? "none" : "block" }}>
                        <p>Generating reports and analyzing time "<span style={{ color: "#04542C" }}>TIMETRAKER</span>" data is an essential part of the process. It helps you gain insights into how time is allocated, assess productivity, and make informed decisions.</p>
                    </div>
                </div>

                <div className='headContainer1' onClick={handleToggle6} >
                    <div className='headIcon1'>
                        <i onClick={handleToggle6} class={toggle6 ? "fa-solid fa-plus" : "fa-solid fa-minus"}></i>
                        <h1 onClick={handleToggle6}>How can I generate reports and analyze time "<span style={{ color: "#04542C" }}>TIMETRAKER</span>" data?</h1>
                    </div>
                    <div className='para1' style={{ display: toggle6 ? "none" : "block" }}>
                        <p>Yes, we can incorporate SEO best practices into the website development process to help improve your site's visibility on search engines.</p>
                    </div>
                </div>
            </div>

            <div
                className="contact"
                style={{ marginTop: "50px", marginBottom: "50px", padding: "0px 20px" }}

            >
                <div className="contact-top">
                    <div className="formlogo" >
                        <img src={formlogo} alt="" />
                    </div>
                    <p>
                        "We're here to assist you with any questions you may have. Complete
                        the form, and we'll respond promptly."
                    </p>
                </div>

                <Form className="contact-container" onSubmit={handleSubmit}>
                    <Row className="mb-3">
                        <Form.Group as={Col} controlId="formGridPassword">
                            <Form.Label>
                                First Name <i className="fa-solid fa-asterisk"></i>{" "}
                            </Form.Label>
                            <Form.Control
                                type="text"
                                name="firstName"
                                value={formData.firstName}
                                onChange={handleChange}
                            />
                            {errors.firstName && <span>{errors.firstName}</span>}
                        </Form.Group>

                        <Form.Group as={Col} controlId="formGridPassword">
                            <Form.Label>
                                Last Name <i className="fa-solid fa-asterisk"></i>{" "}
                            </Form.Label>
                            <Form.Control
                                type="text"
                                name="lastName"
                                value={formData.lastName}
                                onChange={handleChange}
                            />
                            {errors.lastName && <span>{errors.lastName}</span>}
                        </Form.Group>
                    </Row>

                    <Form.Group className="mb-3" controlId="formGridPassword">
                        <Form.Label>
                            Email <i className="fa-solid fa-asterisk"></i>{" "}
                        </Form.Label>
                        <Form.Control
                            type="text"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                        />
                        {errors.email && <span>{errors.email}</span>}
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formGroupPassword">
                        <Form.Label>
                            Company Name <i className="fa-solid fa-asterisk"></i>{" "}
                        </Form.Label>
                        <Form.Control
                            type="text"
                            name="companyName"
                            value={formData.companyName}
                            onChange={handleChange}
                        />
                        {errors.companyName && <span>{errors.companyName}</span>}
                    </Form.Group>

                    <Form.Group className="mb-4" controlId="formGroupPassword">
                        <Form.Label>
                            Phone Number <i className="fa-solid fa-asterisk"></i>{" "}
                        </Form.Label>

                        <Form.Control
                            type="number"
                            name="phoneNumber"
                            value={formData.phoneNumber}
                            onChange={handleChange}
                        />
                        {errors.phoneNumber && <span>{errors.phoneNumber}</span>}
                    </Form.Group>

                    <textarea
                        id=""
                        cols=""
                        rows=""
                        placeholder="How can we help you ?"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                    ></textarea>
                    {errors.message && <span>{errors.message}</span>}

                    <div className="d-grid gap-2 mt-4 mb-5 contactBtn">
                        <Button size="lg" type="submit">
                            Send Message
                        </Button>
                    </div>
                </Form>
            </div>

            <Footer />
            <Footer2 />

        </div>

    )
}

export default NeedAssistance