import React, { useState, useEffect, useContext } from 'react';
import { MyContext } from '../../../context/MyProvider';

const TimeOffFilter = () => {

    const {  selectedPropertiesForTimeOff, setSelectedPropertiesForTimeOff } = useContext(MyContext);


    const data = {

    }

    const handleCheckboxChange = (property) => {
        setSelectedPropertiesForTimeOff(prevState => ({
            ...prevState,
            [property]: !prevState[property],
        }));
    };



    return (
        <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ width: "85%" }}>
                {/* Checkboxes for selecting properties */}
                {Object.keys(selectedPropertiesForTimeOff).map(property => (
                    <label style={{fontSize: "19px"}} className='left_box_check' key={property}>
                        <input
                            type="checkbox"
                            defaultChecked
                            className='input_check'
                            checked={selectedPropertiesForTimeOff[property]}
                            onChange={() => handleCheckboxChange(property)}
                        />
                        { property}
                    </label>
                ))}
                {Object.keys(data).map(property => (
                    <label className='left_box_check' key={property}>
                        <input
                            type="checkbox"
                            defaultChecked
                            className='input_check'
                            checked={data[property]}
                        />
                        {property}
                    </label>
                ))}
            </div>
        </div>
    );
};

export default TimeOffFilter;
