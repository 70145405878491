import React from 'react'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import signupImg2 from "../img/user2.png";
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';

const ProfileComponent = () => {
    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const adminloginInfo = JSON.parse(localStorage.getItem("adminLoginInfo"));
    const userLoginInfo = JSON.parse(localStorage.getItem("userLoginInfo"));

    if (adminloginInfo) {
        var profileInfo = adminloginInfo;
        var token = adminloginInfo?.token
        var companyID = adminloginInfo?._id;
    } else {
        var profileInfo = userLoginInfo;
        var token = userLoginInfo?.token
        var companyID = userLoginInfo?.companyID;
    }

    //     // config


    const config = {
        headers: {
            Accept: "application/json",
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`
        },
    };


    const navigate = useNavigate()
    const handleLogout = () => {
        if (profileInfo.role == "admin") {
            localStorage.removeItem("adminLoginInfo");
            navigate("/login")
        } else {
            localStorage.removeItem("userLoginInfo");
            navigate("/login")
        }
    };

    const deleteDemoData = async (req, res) => {
        try {
            const { data } = await axios.get(`https://app.timetraker.com/backend/api/remove-demo-data/?companyID=${companyID}`, config)
            if (data) {
                successNotify('Demo data has been removed!!')
                handleCloseUserMenu()
            }
        } catch (error) {
            console.log("error", error)
            errorNotify("Something went wrong!!")
        }
    }



    const successNotify = (message) => {
        toast.success(message, {
            position: 'top-right',
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    };

    const errorNotify = (message) => {
        toast.error(message, {
            position: 'top-right',
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    };


    return (
        <abbr title="Profile">
            <ToastContainer />
            <img
                onClick={handleOpenUserMenu}
                src={signupImg2}
                alt=""
            />
            <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
            >
                <div style={{ display: "flex", flexDirection: "column", gap: "10px", alignItems: "start", background: "white", padding: "10px 15px" }} >
                    <Typography className='profile_typo' textAlign="center"><i style={{ marginRight: "7px", color: "gray" }} class="fa-solid fa-user"></i> {profileInfo?.name} ({profileInfo?.role}) </Typography>
                    {adminloginInfo?.token ?
                        <Typography className='profile_typo' onClick={() => { navigate("/set-up-account") }} textAlign="center"><i style={{ marginRight: "7px", color: "gray" }} class="fa-solid fa-gear"></i> Setup Wizard </Typography>
                        : null}
                    {adminloginInfo?.token ?
                        <Typography className='profile_typo' onClick={deleteDemoData} textAlign="center"><i style={{ marginRight: "7px", color: "gray" }} class="fa-solid fa-trash"></i> Remove Demo Data</Typography>
                        : null}
                    <Typography className='profile_typo' onClick={handleLogout} textAlign="center"><i style={{ marginRight: "7px", color: "gray" }} class="fa-solid fa-right-from-bracket"></i> Log Out</Typography>
                </div>
            </Menu>
        </abbr>
    )
}

export default ProfileComponent
