import React from 'react';
import Papa from 'papaparse';
import { saveAs } from 'file-saver';

const ExpenseUserCsv = ({ userTimesheetInfo, selectedRowIds }) => {

  if (selectedRowIds) {
    var filteredData = userTimesheetInfo.filter(item => selectedRowIds.includes(item._id));
  } else {
    var filteredData = userTimesheetInfo;
  }

  const userName = filteredData[0]?.userName;
  const generateCsv = () => {
    // Convert JSON data to CSV format using papaparse
    if (!filteredData || filteredData.length === 0) {
      // Handle the case when there is no data
      return;
    }
    const excludeFields = ['_id', 'userID', 'companyID', 'createdAt', 'updatedAt', '__v', 'sendToQb', 'userName', 'fromQb', 'photo', 'description', 'merchant', 'expenseReport', 'paidBy', 'name', 'rejectReason', 'demo'];

    const newArray = filteredData.map(obj => {
      const filteredObject = Object.fromEntries(
        Object.entries(obj).filter(([key]) => !excludeFields.includes(key))
      );
      return filteredObject;
    });

    // Convert data to CSV format using papaparse
    const csvData = Papa.unparse(newArray, {
      header: true,
    });

    // Adding custom heading row
    const csvWithHeading = `Expenses Summary,User Name: ${userName}\n\n${csvData}`;

    // Create a Blob from the CSV data
    const blob = new Blob([csvWithHeading], { type: 'text/csv;charset=utf-8' });

    // Trigger a file download
    saveAs(blob, `Expense.csv`);
  };

  return (
    <div>
      <button className='button-container-button' onClick={generateCsv}>CSV</button>
    </div>
  );
};

export default ExpenseUserCsv;
