import React, { useEffect } from "react";
import "./Quickbooks.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import logo from "../../img/TimeTrakerLogo.png"
import "./DisconnectQuickbooks.css"


const DisconnectQuickbooks = () => {



    const navigate = useNavigate();
    // config


    const adminloginInfo = JSON.parse(localStorage.getItem("adminLoginInfo"));
    const userLoginInfo = JSON.parse(localStorage.getItem("userLoginInfo"));

    if (adminloginInfo) {
        var companyID = adminloginInfo?._id;
        var TTtoken = adminloginInfo?.token
    } else if (userLoginInfo) {
        var companyID = userLoginInfo?.companyID;
        var TTtoken = userLoginInfo?.token
    }


    var config = {
        headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${TTtoken}`
        },
    };





    const deleteTokenFormMongo = async () => {
        try {
            const { data } = await axios.get(
                `https://app.timetraker.com/backend/api/delete-qb-token?companyID=${companyID}`,
                config
            );
            if (data) {
                localStorage.removeItem("dataFormQuick")
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        deleteTokenFormMongo()
    }, [])


    return (
        <div className="disconnec_parent">
            <div className="disconnect_logo_parent" >
                <img className="disconnect_logo" src={logo} alt="Logo" />
                {/* <h2>TimeTraker</h2> */}
            </div>
            <div className="disconnect_content">
                <div>
                    <p className="disconnect_text"> QuickBooks Disconnected </p>
                    <div>
                        <p>
                            Your QuickBooks integration has been disconnected. You will no longer to be able to share data directly to your QuickBooks account from TimeTraker.
                        </p>
                        <p>
                            If you’d like to re-connect TimeTraker and your QuickBooks account, visit our <a href="https://app.timetraker.com/quickbooks-manual">help guide here</a>
                        </p>
                        <p>For general support contact us at <a href="mailto: support@flashcubeit.com"> support@flashcubeit.com </a></p>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default DisconnectQuickbooks;
