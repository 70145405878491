import React, { useState, useContext, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { StyledSideDiv, StyledListItemLog, ListStyled, StyledTypography, StyledListItem, StyledLogo } from './Styled';
import { useNavigate, useLocation } from 'react-router-dom';
import './Style.css'
import { MyContext } from '../context/MyProvider'
import logo from "../img/logoUpdated.svg"
import icon1 from '../img/icon 1.png'
import icon2 from '../img/icon 2.png'
import icon3 from '../img/icon 3.png'
import icon4 from '../img/icon 4.png'
import icon5 from '../img/icon 5.png'
import icon6 from '../img/icon 6.png'
import icon7 from '../img/icon 7.png'
import icon8 from '../img/icon 8.png'
import icon9 from '../img/icon 9.png'
import icon10 from '../img/icon 10.png'
import icon11 from '../img/icon 11.png'
import axios from "axios"

const SideBar = () => {
    const { sideBarStatus, setSideBarStatus, activeItem, setActiveItem, show, setShow, additionalSettings, setAdditionalSettings } = useContext(MyContext);

    const handleClick = () => {
        if (sideBarStatus == true) {
            setSideBarStatus(false)
        } else {
            setSideBarStatus(true)
        }
    }

    const screenWidth = window.innerWidth;
    if (screenWidth >= 840) {
        var forPhoneScreenNoDisplay = true
    } else {
        var forPhoneScreenNoDisplay = false
    }

    const adminloginInfo = JSON.parse(localStorage.getItem("adminLoginInfo"));
    const userLoginInfo = JSON.parse(localStorage.getItem("userLoginInfo"));


    if (adminloginInfo) {
        var companyID = adminloginInfo?._id;
        var token = adminloginInfo?.token
    } else if (userLoginInfo) {
        var companyID = userLoginInfo?.companyID;
        var token = userLoginInfo?.token
    }

    const config = {
        headers: {
            Accept: "application/json",
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`
        },
    };

    const location = useLocation();

    const getSettings = async () => {
        try {
            const { data } = await axios.get(`https://app.timetraker.com/backend/api/get-settings/?companyID=${companyID}`, config)
            if (data) {
                setAdditionalSettings({
                    timer: data.settings.timesheetSettings.timer,
                    clockIn: data.settings.timesheetSettings.clockIn,
                    timesheetApprover: data.settings.timesheetSettings.approver,
                    expenseApprover: data.settings.expenseSettings.approver,
                    receipt: data.settings.expenseSettings.receipt
                })
            }
        } catch (error) {
            console.log("error", error)
        }
    }


    var myTimesheets = { display: 'none' }
    var task = { display: 'none' }
    var myExpenses = { display: 'none' }
    var project = { display: 'none' }
    var dashboard = { display: 'none' }
    var role = { display: 'none' }
    var reports = { display: 'none' }
    var profile = { display: 'none' }
    var employe = { display: 'none' }
    var timesheets = { display: 'none' }
    var expenses = { display: 'none' }
    var sync = { display: 'none' }
    var bamboohr = { display: 'none' }
    var settings = { display: 'none' }

    if (adminloginInfo) {
        var homeData = (adminloginInfo)
        var logTime = adminloginInfo.logTime
    }
    else if (userLoginInfo) {
        var homeData = (userLoginInfo)
        var logTime = userLoginInfo.logTime
    }

    if (homeData?.accessibility?.myTimesheets === true) {
        myTimesheets = { display: 'flex' }
    }
    if (homeData?.accessibility?.task === true) {
        task = { display: 'flex' }
    }
    if (homeData?.accessibility?.myExpenses === true) {
        myExpenses = { display: 'flex' }
    }
    if (homeData?.accessibility?.project === true) {
        project = { display: 'flex' }
    }
    if (homeData?.accessibility?.dashboard === true) {
        dashboard = { display: 'flex' }
    }
    if (homeData?.accessibility?.role === true) {
        role = { display: 'flex' }
    }
    if (homeData?.accessibility?.reports === true) {
        reports = { display: 'flex' }
    }
    if (homeData?.accessibility?.profile === true) {
        if (adminloginInfo) {
            profile = { display: 'none' }
        } else {
            profile = { display: 'flex' }
        }
    }
    if (homeData?.accessibility?.dashboard === true) {
        settings = { display: 'flex' }
    }
    if (homeData?.accessibility?.employe === true) {
        employe = { display: 'flex' }
    }
    if (homeData?.accessibility?.timesheets === true) {
        if (homeData.role === "manager" || homeData.role === "user") {
            if (additionalSettings.timesheetApprover === false) {
                timesheets = { display: 'none' }
            } else {
                timesheets = { display: 'flex' }
            }
        } else {
            timesheets = { display: 'flex' }
        }
    }
    if (homeData?.accessibility?.expenses === true) {
        if (homeData.role === "manager" || homeData.role === "user") {
            if (additionalSettings.expenseApprover === false) {
                expenses = { display: 'none' }
            } else {
                expenses = { display: 'flex' }
            }
        } else {
            expenses = { display: 'flex' }
        }
    }
    if (homeData?.accessibility?.sync === true) {
        sync = { display: 'flex' }
    }
    if (homeData?.accessibility?.bamboohr === true) {
        bamboohr = { display: 'flex' }
    }

    const navigate = useNavigate();
    const Expenses = () => {
        navigate('/my-expense');
    };
    const TimeSheet = () => {
        navigate('/my-time');
    };
    const Task = () => {
        navigate('/task');
    };
    const Project = () => {
        navigate('/project');
    };
    const Dashboard = () => {
        navigate('/admin-dashboard-v2');
    };
    const WhoIsWroking = () => {
        navigate('/who-is-working');
    };
    const Roles = () => {
        navigate('/accessibility');
    };
    const Reports = () => {
        navigate('/reports');
    };
    const Profile = () => {
        navigate('/profile');
    };
    const AddUser = () => {
        navigate('/add-user');
    };
    const ManageUser = () => {
        navigate('/manage-user');
    };
    const Team = () => {
        navigate('/team');
    };
    const TimeSheetAprover = () => {
        navigate('/timesheet-aprove');
    };
    const ExpenseAprover = () => {
        navigate('/expense-aprove');
    };
    const TimesheetReport = () => {
        navigate('/timesheet-reports');
    };

    const ExpenseReport = () => {
        navigate('/expense-reports');
    };
    const TimeOffReport = () => {
        navigate('/timeOff-reports');
    };
    const quickbooks = () => {
        navigate('/quickbooks');
    };
    const bamboohrRoute = () => {
        navigate('/bamboohr');
    };
    const xero = () => {
        navigate('/xero');
    };
    const chartOfAccount = () => {
        navigate('/chart-of-account');
    };
    const companySettings = () => {
        navigate('/company-settings');
    };
    const TimehseetSettings = () => {
        navigate('/time-off-policy');
    };

    useEffect(() => {


        switch (location.pathname) {
            case '/my-expense':
                setActiveItem('myExpense');
                break;
            case '/my-time':
                setActiveItem('myTime');
                break;
            case '/task':
                setActiveItem('project');
                break;
            case '/assign':
                setActiveItem('project');
                break;
            case '/project':
                setActiveItem('project');
                break;
            case '/admin-dashboard-v2':
                setActiveItem('dashboard');
                break;
            case '/who-is-working':
                setActiveItem('WhoIsWroking');
                break;
            case '/accessibility':
                setActiveItem('account');
                break;
            case '/reports':
                setActiveItem('reports');
                break;
            case '/profile':
                setActiveItem('profile');
                break;
            case '/add-user':
                setActiveItem('users');
                break;
            case '/manage-user':
                setActiveItem('users');
                break;
            case '/team':
                setActiveItem('users');
                break;
            case '/time-off-policy':
                setActiveItem('settings');
                break;
            case '/holiday':
                setActiveItem('settings');
                break;
            case '/timesheet-aprove':
                setActiveItem('timesheets');
                break;
            case '/expense-aprove':
                setActiveItem('expenses');
                break;
            case '/timesheet-reports':
                setActiveItem('reports');
                break;
            case '/expense-reports':
                setActiveItem('reports');
                break;
            case '/quickbooks':
                setActiveItem('quickBooks');
                break;
            case '/bamboohr':
                setActiveItem('bamboohr');
                break;
            case '/xero':
                setActiveItem('xero');
                break;
            case '/chart-of-account':
                setActiveItem('settings');
                break;
            case '/company-settings':
                setActiveItem('settings');
                break;
            case '/time-settings':
                setActiveItem('settings');
                break;
            case '/expense-settings':
                setActiveItem('settings');
                break;
            default:
                setActiveItem('');
        }
    }, [location.pathname, logTime, navigate, setActiveItem]);





    const handleItemClick = (item) => {
        setActiveItem(item);
    };

    const handleShowDropDown = (item) => {
        handleItemClick(item)
        setShow(show === item ? false : item)
    }


    useEffect(() => {
        getSettings()
    }, [])


    const [imageExists, setImageExists] = useState(true);
    const imageUrl = `https://app.timetraker.com/backend/api/logo/${companyID}`;

    useEffect(() => {
        const img = new Image();
        img.src = imageUrl;

        img.onload = () => setImageExists(true);
        img.onerror = () => setImageExists(false);
    }, [imageUrl]);


    return (
        <StyledSideDiv className={sideBarStatus ? "active" : "leftdash1"} style={sideBarStatus == true & forPhoneScreenNoDisplay == false ? { width: '100%' } : {}}>


            <div >
                <Box height="100%" display="flex" flexDirection="column">
                    <Box>
                        <ListStyled>
                            <StyledListItemLog>
                                <StyledLogo variant="h5" sx={{
                                    color: 'white',
                                    fontSize: '48px',
                                    display: "flex",
                                    marginTop: "10px",
                                    justifyContent: "start"
                                }}>
                                    <img
                                        className="logo_img"
                                        src={imageExists ? imageUrl : logo}
                                        alt="Company Logo"
                                    />
                                </StyledLogo>
                                <div onClick={handleClick}>
                                    <i style={{ color: 'white', marginBottom: '30px', fontSize: '25px', cursor: 'pointer', paddingTop: "8px" }} className='fas fa-bars'></i>
                                </div>
                            </StyledListItemLog>
                            <StyledListItem className={`hoc_parent ${activeItem === 'myTime' ? 'active-tab-in-side' : ''}`} onClick={() => handleItemClick('myTime')} style={myTimesheets}>
                                <div>
                                    <img style={{ width: "30px", marginLeft: "-3px" }} className='side_bar_icons' src={icon1} alt='logo' />
                                </div>
                                <StyledTypography className='hoc_text' variant="h5" cursor="pointer" onClick={TimeSheet}>
                                    My Time
                                </StyledTypography>
                            </StyledListItem>
                            <StyledListItem className={`hoc_parent ${activeItem === 'myExpense' ? 'active-tab-in-side' : ''}`} onClick={() => handleItemClick('myExpense')} style={myExpenses}>
                                <div>
                                    <img className='side_bar_icons' src={icon2} alt='logo' />
                                </div>
                                <StyledTypography className='hoc_text' cursor="pointer" variant="h5" onClick={Expenses} style={{ paddingLeft: '3px' }} >
                                    My Expense
                                </StyledTypography>
                            </StyledListItem>



                            {/* <StyledListItem className={`hoc_parent ${activeItem === 'task' ? 'active-tab-in-side' : ''}`} onClick={() => handleItemClick('task')} style={task}>
                                <div>
                                    <img className='side_bar_icons' src={icon3} alt='logo' />
                                </div>
                                <StyledTypography className='hoc_text' variant="h5" onClick={Task} style={{ paddingLeft: '4px' }} >
                                    Tasks
                                </StyledTypography>
                            </StyledListItem> */}



                            <StyledListItem className={`hoc_parent ${activeItem === 'project' ? 'active-tab-in-side' : ''}`} onClick={() => handleItemClick('project')} style={project}>
                                <div>
                                    <img style={{ width: "25px" }} className='side_bar_icons' src={icon4} alt='logo' />
                                </div>
                                <StyledTypography className='hoc_text' onClick={Project} variant="h5" style={{ paddingLeft: '2px' }} >
                                    Projects
                                </StyledTypography>
                            </StyledListItem>


                            <StyledListItem className={`hoc_parent ${activeItem === 'dashboard' ? 'active-tab-in-side' : ''}`} onClick={() => handleItemClick('dashboard')} style={dashboard}>
                                <div>
                                    <img className='side_bar_icons' src={icon5} alt='logo' />
                                </div>
                                <StyledTypography className='hoc_text' onClick={Dashboard} variant="h5" style={{ paddingLeft: '3px' }} >
                                    Dashboard
                                </StyledTypography>
                            </StyledListItem>

                            <StyledListItem className={`hoc_parent ${activeItem === 'WhoIsWroking' ? 'active-tab-in-side' : ''}`} onClick={() => handleItemClick('WhoIsWroking')} style={additionalSettings.clockIn === false ? { display: "none" } : dashboard}>
                                <div>
                                    <img className='side_bar_icons' src={icon5} alt='logo' />
                                </div>
                                <StyledTypography className='hoc_text' onClick={WhoIsWroking} variant="h5" style={{ paddingLeft: '3px' }} >
                                    Who's Working
                                </StyledTypography>
                            </StyledListItem>


                            <StyledListItem sx={show === "users" ? { marginBottom: "136px" } : { marginBottom: "0px" }} className={`hoc_parent ${activeItem === 'users' ? 'active-tab-in-side userHoverActive user-dropdown' : 'userHoverActive user-dropdown'}`} style={employe}>
                                <div>
                                    <img style={{ width: "30px", marginLeft: "-4px" }} className='side_bar_icons' src={icon8} alt='logo' />
                                </div>
                                <StyledTypography onClick={() => handleShowDropDown("users")} style={{ paddingLeft: '2px' }} className='hoc_text user-dropdown' variant="h5">
                                    Users
                                    <i style={{ marginLeft: '15px', fontSize: '15px' }} class="fa-solid fa-circle-chevron-down"></i>

                                </StyledTypography>

                                <ul class="user-dropdown_menu dropdown_menu-5">
                                    <li onClick={AddUser} style={show === "users" ? { display: "flex" } : { display: "none" }} class="dropdown_item-1 li_items hoc_parent1 "><i style={{ marginRight: '6px', fontSize: '6px' }} class="fa-regular fa-circle"></i>Add User</li>
                                    <li onClick={ManageUser} style={show === "users" ? { display: "flex" } : { display: "none" }} class="dropdown_item-2 li_items hoc_parent2 "><i style={{ marginRight: '6px', fontSize: '6px' }} class="fa-regular fa-circle"></i>Manage User</li>
                                    <li onClick={Team} style={show === "users" ? { display: "flex" } : { display: "none" }} class="dropdown_item-2 li_items hoc_parent2 "><i style={{ marginRight: '6px', fontSize: '6px' }} class="fa-regular fa-circle"></i>Team</li>
                                </ul>

                            </StyledListItem>





                            <StyledListItem className={`hoc_parent ${activeItem === 'timesheets' ? 'active-tab-in-side' : ''}`} onClick={() => handleItemClick('timesheets')} style={timesheets}>
                                <div>
                                    <img style={{ width: "25px", marginLeft: "-4px" }} className='side_bar_icons' src={icon9} alt='logo' />
                                </div>
                                <StyledTypography className='hoc_text' onClick={TimeSheetAprover} variant="h5" style={{ paddingLeft: '7px' }} >
                                    Timesheets
                                </StyledTypography>
                            </StyledListItem>

                            <StyledListItem className={`hoc_parent ${activeItem === 'expenses' ? 'active-tab-in-side' : ''}`} onClick={() => handleItemClick('expenses')} style={expenses}>
                                <div>
                                    <img style={{ width: "24px" }} className='side_bar_icons' src={icon10} alt='logo' />
                                </div>
                                <StyledTypography className='hoc_text' onClick={ExpenseAprover} variant="h5" style={{ paddingLeft: '3px' }} >
                                    Expenses
                                </StyledTypography>
                            </StyledListItem>



                            <StyledListItem className={`hoc_parent ${activeItem === 'quickBooks' ? 'active-tab-in-side' : ''}`} onClick={() => handleItemClick('quickBooks')} style={sync}>
                                <div>
                                    <img style={{ width: "24px" }} className='side_bar_icons' src={icon6} alt='logo' />
                                </div>
                                <StyledTypography className='hoc_text' onClick={quickbooks} variant="h5" style={{ paddingLeft: '3px' }} >
                                    QuickBooks
                                </StyledTypography>
                            </StyledListItem>
                            <StyledListItem className={`hoc_parent ${activeItem === 'bamboohr' ? 'active-tab-in-side' : ''}`} onClick={() => handleItemClick('bamboohr')} style={bamboohr}>
                                <div>
                                    <img style={{ width: "24px" }} className='side_bar_icons' src={icon6} alt='logo' />
                                </div>
                                <StyledTypography className='hoc_text' onClick={bamboohrRoute} variant="h5" style={{ paddingLeft: '3px' }} >
                                BambooHR
                                </StyledTypography>
                            </StyledListItem>

                            {/* <StyledListItem className={`hoc_parent ${activeItem === 'xero' ? 'active-tab-in-side' : ''}`} onClick={() => handleItemClick('xero')} style={sync}>
                                <div>
                                    <img style={{ width: "24px" }} className='side_bar_icons' src={icon6} alt='logo' />
                                </div>
                                <StyledTypography className='hoc_text' onClick={xero} variant="h5" style={{ paddingLeft: '3px' }} >
                                    Xero
                                </StyledTypography>
                            </StyledListItem> */}


                            <StyledListItem sx={show === "settings" ? { marginBottom: "136px" } : { marginBottom: "0px" }} className={`hoc_parent ${activeItem === 'settings' ? 'active-tab-in-side userHoverActive user-dropdown' : 'userHoverActive user-dropdown'}`} style={settings}>
                                <div>
                                    <img style={{ width: "30px", marginLeft: "-4px" }} className='side_bar_icons' src={icon8} alt='logo' />
                                </div>
                                <StyledTypography onClick={() => handleShowDropDown("settings")} style={{ paddingLeft: '2px' }} className='hoc_text user-dropdown' variant="h5">
                                    Settings
                                    <i style={{ marginLeft: '15px', fontSize: '15px' }} class="fa-solid fa-circle-chevron-down"></i>

                                </StyledTypography>

                                <ul class="user-dropdown_menu dropdown_menu-5">
                                    <li onClick={companySettings} style={show === "settings" ? { display: "flex" } : { display: "none" }} class="dropdown_item-1 li_items hoc_parent1 "><i style={{ marginRight: '6px', fontSize: '6px' }} class="fa-regular fa-circle"></i>Company Settings</li>
                                    <li onClick={TimehseetSettings} style={show === "settings" ? { display: "flex" } : { display: "none" }} class="dropdown_item-2 li_items hoc_parent2 "><i style={{ marginRight: '6px', fontSize: '6px' }} class="fa-regular fa-circle"></i>Timesheets Settings</li>
                                    <li onClick={chartOfAccount} style={show === "settings" ? { display: "flex" } : { display: "none" }} class="dropdown_item-2 li_items hoc_parent2 "><i style={{ marginRight: '6px', fontSize: '6px' }} class="fa-regular fa-circle"></i>Expense Settings</li>
                                </ul>

                            </StyledListItem>

                            <StyledListItem className={`hoc_parent ${activeItem === 'profile' ? 'active-tab-in-side' : ''}`} onClick={() => handleItemClick('profile')} style={profile}>
                                <div>
                                    <img style={{ width: "22px" }} className='side_bar_icons' src={icon7} alt='logo' />
                                </div>
                                <StyledTypography className='hoc_text' onClick={Profile} variant="h5" style={{ paddingLeft: '5px' }} >
                                    Profile
                                </StyledTypography>
                            </StyledListItem>

                            {/* have to change if needed */}
                            {/* <StyledListItem style={reports}>
                                <StyledTypography onClick={TimesheetReport} variant="h5">
                                    Report
                                </StyledTypography>
                            </StyledListItem> */}

                            <StyledListItem className={`hoc_parent ${activeItem === 'reports' ? 'active-tab-in-side' : ''}`} style={reports} class="dropdown">
                                <div>
                                    <img style={{ width: "24px" }} className='side_bar_icons' src={icon11} alt='logo' />
                                </div>
                                <StyledTypography onClick={() => handleShowDropDown("reports")} class="dropdown dropdown-5 hoc_text" style={{ padding: "0px 0px 0px 25px", margin: "0px" }}>
                                    Reports
                                    <i style={{ marginLeft: '15px', fontSize: '15px' }} class="fa-solid fa-circle-chevron-down"></i>
                                    <ul class="dropdown_menu dropdown_menu-5">
                                        <li style={show === "reports" ? { display: "flex" } : { display: "none" }} onClick={TimesheetReport} class="dropdown_item-1 li_items hoc_parent1 "><i style={{ marginRight: '6px', fontSize: '6px' }} class="fa-regular fa-circle"></i>Timesheet Reports</li>
                                        <li style={show === "reports" ? { display: "flex" } : { display: "none" }} onClick={ExpenseReport} class="dropdown_item-2 li_items hoc_parent2 "><i style={{ marginRight: '6px', fontSize: '6px' }} class="fa-regular fa-circle"></i>Expense Reports</li>
                                        <li style={show === "reports" ? { display: "flex" } : { display: "none" }} onClick={TimeOffReport} class="dropdown_item-2 li_items hoc_parent2 "><i style={{ marginRight: '6px', fontSize: '6px' }} class="fa-regular fa-circle"></i>Time Off Reports</li>
                                    </ul>
                                </StyledTypography>
                            </StyledListItem>




                        </ListStyled>
                    </Box>
                </Box>
            </div>
        </StyledSideDiv>

    );
};

export default SideBar;
