import React from 'react';
import { Navigate } from 'react-router-dom';
import {
  isAuthenticatedForAddUser,
  isAuthenticatedForMyTimeInAdmin,
  isAuthenticatedForMyTimeInUser,
  isAuthenticatedForTask,
  isAuthenticatedForProject,
  isAuthenticatedForUserProfile,
  isAuthenticatedForAdminProfile,
  isAuthenticatedForUserExpanse,
  isAuthenticatedForAdminExpanse,
  isAuthenticatedForDashboard,
  isAuthenticatedForTimesheetAprove,
  isAuthenticatedForExpenseAprove,
  isAuthenticatedForRole,
  isAuthenticatedForReports,
  isAuthenticatedForSync,
  isAdminChatAccount,
  isAdminDetailsTrackAccount
} from './index';

// It is Higher order component in react

const PrivateRouteForAddUser = ({ children }) => {
  return isAuthenticatedForAddUser() ? <>{children}</> : <Navigate to="/login" />;
};

const PrivateRouteForMyTimeInAdmin = ({ children }) => {
  return isAuthenticatedForMyTimeInAdmin() ? <>{children}</> : <Navigate to="/login" />;
};

const PrivateRouteForMyTimeInUser = ({ children }) => {
  return isAuthenticatedForMyTimeInUser() ? <>{children}</> : <Navigate to="/login" />;
};

const PrivateRouteForTask = ({ children }) => {
  return isAuthenticatedForTask() ? <>{children}</> : <Navigate to="/login" />;
};

const PrivateRouteForProject = ({ children }) => {
  return isAuthenticatedForProject() ? <>{children}</> : <Navigate to="/login" />;
};

const PrivateRouteForUserProfile = ({ children }) => {
  return isAuthenticatedForUserProfile() ? <>{children}</> : <Navigate to="/login" />;
};

const PrivateRouteForAdminProfile = ({ children }) => {
  return isAuthenticatedForAdminProfile() ? <>{children}</> : <Navigate to="/login" />;
};

const PrivateRouteForUserExpense= ({ children }) => {
  return isAuthenticatedForUserExpanse() ? <>{children}</> : <Navigate to="/login" />;
};

const PrivateRouteForAdminExpense= ({ children }) => {
  return isAuthenticatedForAdminExpanse() ? <>{children}</> : <Navigate to="/login" />;
};

const PrivateRouteForDashboard= ({ children }) => {
  return isAuthenticatedForDashboard() ? <>{children}</> : <Navigate to="/login" />;
};

const PrivateRouteForTimesheetAprove= ({ children }) => {
  return isAuthenticatedForTimesheetAprove() ? <>{children}</> : <Navigate to="/login" />;
};

const PrivateRouteForExpenseAprove= ({ children }) => {
  return isAuthenticatedForExpenseAprove() ? <>{children}</> : <Navigate to="/login" />;
};

const PrivateRouteForRole= ({ children }) => {
  return isAuthenticatedForRole() ? <>{children}</> : <Navigate to="/login" />;
};

const PrivateRouteForReports= ({ children }) => {
  return isAuthenticatedForReports() ? <>{children}</> : <Navigate to="/login" />;
};

const PrivateRouteForSync= ({ children }) => {
  return isAuthenticatedForSync() ? <>{children}</> : <Navigate to="/login" />;
};

const PrivateAdminChatAccount= ({ children }) => {
  return isAdminChatAccount() ? <>{children}</> : <Navigate to="/login" />;
};

const PrivateAdminDetailsTrackAccount= ({ children }) => {
  return isAdminDetailsTrackAccount() ? <>{children}</> : <Navigate to="/login" />;
};

export {
  PrivateRouteForAddUser,
  PrivateRouteForMyTimeInAdmin,
  PrivateRouteForMyTimeInUser,
  PrivateRouteForTask,
  PrivateRouteForProject,
  PrivateRouteForUserProfile,
  PrivateRouteForAdminProfile,
  PrivateRouteForUserExpense,
  PrivateRouteForAdminExpense,
  PrivateRouteForDashboard,
  PrivateRouteForTimesheetAprove,
  PrivateRouteForExpenseAprove,
  PrivateRouteForRole,
  PrivateRouteForReports,
  PrivateRouteForSync,
  PrivateAdminChatAccount,
  PrivateAdminDetailsTrackAccount
};
