import React from "react";
import "./PrivacyPolicy.css";

const PrivacyPolicy = () => {
  return (
    <div className="PrivacyPolicyContainer">
      <div className="PrivacyPolicyTop">
        <h6>Privacy Policy</h6>
        <p className="paraTop">Effective date: February 08, 2024</p>
        <p className="paraTop">
        Flash Cube It Services Pvt. Ltd.  ("us", "we", or "our") operates the
          https://timetraker.com website (hereinafter referred to as the
          "Service").
        </p>
        <p className="paraTop">
          This page informs you of our policies regarding the collection, use
          and disclosure of personal data when you use our Service and the
          choices you have associated with that data.
        </p>
        <p className="paraTop">
          We use your data to provide and improve the Service. By using the
          Service, you agree to the collection and use of information in
          accordance with this policy. Unless otherwise defined in this Privacy
          Policy, the terms used in this Privacy Policy have the same meanings
          as in our Terms and Conditions.
        </p>
      </div>

      <div className="Definition">
        <h1>Definitions</h1>

        <h3>Service</h3>
        <p className="defPara">
          Service means the https://timetraker.com website operated by
          Flash Cube It Services Pvt. Ltd.
        </p>

        <h3>Personal Data</h3>
        <p className="defPara">
          Personal Data means data about a living individual who can be
          identified from those data (or from those and other information either
          in our possession or likely to come into our possession).
        </p>

        <h3>Cookies</h3>
        <p className="defPara">
          Cookies are small files stored on your device (computer or mobile
          device).
        </p>

        <h3>Usage Data</h3>
        <p className="defPara">
          Usage Data is data collected automatically either generated by the use
          of the Service or from the Service infrastructure itself (for example,
          the duration of a page visit).
        </p>

        <h3>Data Controller</h3>
        <p className="defPara">
          Data Controller means the natural or legal person who (either alone or
          jointly or in common with other persons) determines the purposes for
          which and the manner in which any personal information are, or are to
          be, processed.
        </p>
        <p className="defPara">
          For the purpose of this Privacy Policy, we are a Data Controller of
          your Personal Data.
        </p>

        <h3>Data Processors (Service Providers)</h3>
        <p className="defPara">
          Data Processor (or Service Provider) means any natural or legal person
          who processes the data on behalf of the Data Controller. We may use
          the services of various Service Providers in order to process your
          data more effectively.
        </p>

        <h3>Data Subject (User)</h3>
        <p className="defPara">
          Data Subject is any living individual who is using our Service and is
          the subject of Personal Data.
        </p>
      </div>

      <div className="information">
        <h1>Information Collection and Use</h1>
        <p className="infoPara">
          We collect several different types of information for various purposes
          to provide and improve our Service to you.
        </p>

        <h1>Types of Data Collected</h1>

        <h5>Personal Data</h5>
        <p className="infoPara">
          While using our Service, we may ask you to provide us with certain
          personally identifiable information that can be used to contact or
          identify you ("Personal Data"). Personally identifiable information
          may include, but is not limited to:
        </p>

        <ul>
          <li className="infoPara">Email address</li>
          <li className="infoPara">Your name</li>
          <li className="infoPara">Phone number</li>
          <li className="infoPara">Company Name</li>
          <li className="infoPara">Cookies and Usage Data</li>
        </ul>

        <p className="infoPara">
          We may use your Personal Data to contact you with newsletters,
          marketing or promotional materials and other information that may be
          of interest to you. You may opt out of receiving any, or all, of these
          communications from us by following the unsubscribe link or the
          instructions provided in any email we send.
        </p>

        <h5>Usage Data</h5>
        <p className="infoPara">
          We may also collect information that your browser sends whenever you
          visit our Service or when you access the Service by or through a
          mobile device ("Usage Data").
        </p>
        <p className="infoPara">
          This Usage Data may include information such as your computer's
          Internet Protocol address (e.g. IP address), browser type, browser
          version, the pages of our Service that you visit, the time and date of
          your visit, the time spent on those pages, unique device identifiers
          and other diagnostic data.
        </p>
        <p className="infoPara">
          When you access the Service with a mobile device, this Usage Data may
          include information such as the type of mobile device you use, your
          mobile device unique ID, the IP address of your mobile device, your
          mobile operating system, the type of mobile Internet browser you use,
          unique device identifiers and other diagnostic data.
        </p>

        <h5>Tracking Cookies Data</h5>
        <p className="infoPara">
          We use cookies and similar tracking technologies to track the activity
          on our Service and we hold certain information.
        </p>
        <p className="infoPara">
          Cookies are files with a small amount of data which may include an
          anonymous unique identifier. Cookies are sent to your browser from a
          website and stored on your device. Other tracking technologies are
          also used such as beacons, tags and scripts to collect and track
          information and to improve and analyse our Service.
        </p>
        <p className="infoPara">
          You can instruct your browser to refuse all cookies or to indicate
          when a cookie is being sent. However, if you do not accept cookies,
          you may not be able to use some portions of our Service.
        </p>
      </div>

      <div className="useData">
        <h1>Use of Data</h1>
        <p className="infoPara">
        Flash Cube It Services Pvt. Ltd. uses the collected data for various purposes:
        </p>

        <ul>
          <li className="infoPara">To provide and maintain our Service</li>
          <li className="infoPara">
            To notify you about changes to our Service
          </li>
          <li className="infoPara">
            To allow you to participate in interactive features of our Service
            when you choose to do so
          </li>
          <li className="infoPara"> To provide customer support</li>
          <li className="infoPara">
            To gather analysis or valuable information so that we can improve
            our Service
          </li>
          <li className="infoPara">
            To detect, prevent and address technical issues
          </li>
          <li className="infoPara">
            To provide you with news, special offers and general information
            about other goods, services and events which we offer that are
            similar to those that you have already purchased or enquired about
            unless you have opted not to receive such information
          </li>
        </ul>
      </div>

      <div className="securityData">
        <h1>Security of Data</h1>
        <p className="securityPara">
          The security of your data is important to us but remember that no
          method of transmission over the Internet or method of electronic
          storage is 100% secure. While we strive to use commercially acceptable
          means to protect your Personal Data, we cannot guarantee its absolute
          security.
        </p>

        <h5>Our Policy on "Do Not Track" Signals under the (DPDP) Act, 2023</h5>
        <p className="securityPara">
          We do not support Do Not Track ("DNT"). Do Not Track is a preference
          you can set in your web browser to inform websites that you do not
          want to be tracked.
        </p>
        <p>
          You can enable or disable Do Not Track by visiting the Preferences or
          Settings page of your web browser.
        </p>

        <h5>Your Data Protection Rights under the (DPDP) Act, 2023</h5>
        <p className="securityPara">
          If you are a resident of the India, you have certain data protection
          rights. Flash Cube It Services Pvt. Ltd. aims to take reasonable steps to
          allow you to correct, amend, delete or limit the use of your Personal
          Data.
        </p>
        <p>
          If you wish to be informed about what Personal Data we hold about you
          and if you want it to be removed from our systems, please contact us.
        </p>
      </div>

      <div className="Privacy">
        <h1>Changes to This Privacy Policy</h1>
        <p className="securityPara">
          We may update our Privacy Policy from time to time. We will notify you
          of any changes by posting the new Privacy Policy on this page..
        </p>
        <p className="securityPara">
          We will let you know via email and/or a prominent notice on our
          Service, prior to the change becoming effective and update the
          "effective date" at the top of this Privacy Policy.
        </p>
        <p className="securityPara">
          You are advised to review this Privacy Policy periodically for any
          changes. Changes to this Privacy Policy are effective when they are
          posted on this page.
        </p>
      </div>

      <div className="Contact">
        <h1>Contact Us</h1>
        <h5 style={{ fontSize: "17px" }}>
          If you have any questions about this Privacy Policy, please contact
          us:
        </h5>
        <ul>
          <li className="securityPara">By email: support@flashcubeit.com</li>
        </ul>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
