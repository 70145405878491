import React, { useContext } from "react";
import "../../AddUser.css";
import { Col, Form, Container, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import AdminLayout from "../../../../hoc/AdminLayout";
import { MyContext } from "../../../../context/MyProvider";
import { ToastContainer, toast } from 'react-toastify';
import ProfileComponent from "../../../../components/ProfileComponent";
import 'react-toastify/dist/ReactToastify.css';


const UserHolidayDetails = ({ newUser, editTab, handleClose }) => {
    const navigate = useNavigate();

    const { setActivTab, setNewUser } = useContext(MyContext);
    console.log("newUser", newUser)

    const adminloginInfo = JSON.parse(localStorage.getItem("adminLoginInfo"));
    const userLoginInfo = JSON.parse(localStorage.getItem("userLoginInfo"));


    if (adminloginInfo) {
        var companyID = adminloginInfo?._id;
        var token = adminloginInfo?.token
    } else if (userLoginInfo) {
        var companyID = userLoginInfo?.companyID;
        var token = userLoginInfo?.token
    }


    // config

    const config = {
        headers: {
            Accept: "application/json",
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`
        },
    };

    const [holiday, setHoliday] = useState([]);
    const [allHoliday, setAllHoliday] = useState([]);
    const [remaningHoliday, setRemaningHoliday] = useState([]);
    const [selectdHoliday, setSelectdHoliday] = useState("");



    const getUserHoliday = async () => {
        try {
            const { data } = await axios.get(
                `https://app.timetraker.com/backend/api/get-holiday-for-users/?userID=${newUser?._id}`,
                config
            );
            if (data) {
                setHoliday(data.allHoliday)
            }
        } catch (error) {
            console.log("error from get user timesheet api ", error);
        }
    };

    const getAllHoliday = async () => {
        try {
            const { data } = await axios.get(
                `https://app.timetraker.com/backend/api/get-holiday/?companyID=${companyID}`,
                config
            );
            if (data) {
                setAllHoliday(data.allHoliday)
            }
        } catch (error) {
            console.log("error from get user timesheet api ", error);
        }
    };

    const handleAddHoliday = (e) => {
        const today = new Date();
        const formattedDate = today.toISOString().split('T')[0];

        if (e.target.value === "selectAll") {
            const newData = allHoliday.map((item) => ({
                name: item.name,
                occursYearly: item.occursYearly,
                userID: newUser?._id,
                userName: newUser?.name,
                hireDate: newUser?.hireDate,
                add: true,
                date: formattedDate
            }));

            setHoliday(newData);
            setSelectdHoliday("");
        }
        else {
            setSelectdHoliday(e.target.value)
            const addedHoliday = allHoliday.filter(item => item?.name === e.target.value);


            const newData = [{
                name: addedHoliday[0].name,
                occursYearly: addedHoliday[0].occursYearly,
                userID: newUser?._id,
                userName: newUser?.name,
                hireDate: newUser?.hireDate,
                add: true,
                date: formattedDate
            }]
            setHoliday(prevHoliday => [...prevHoliday, ...newData]);
            setSelectdHoliday("")
        }
    }

    const removeHolidayByName = (holidayNameToRemove) => {
        const updatedHoliday = holiday.filter(p => p.name !== holidayNameToRemove);
        setHoliday(updatedHoliday);

        const addedHoliday = allHoliday.filter(item => item?.name === holidayNameToRemove);

        const newData = [{
            name: addedHoliday[0].name,
            occursYearly: addedHoliday[0].occursYearly,
            userID: newUser?._id,
            userName: newUser?.name,
            hireDate: newUser?.hireDate,
            add: false
        }]
        setHoliday(prevHoliday => [...prevHoliday, ...newData]);
    };


    const handleSaveData = async (e, next) => {
        try {
            const { data } = await axios.post(
                `https://app.timetraker.com/backend/api/update-user-holiday/?companyID=${companyID}`,
                holiday,
                config
            );
            if (data) {

                if (editTab) {
                    handleClose()
                } else {
                    setActivTab("user")
                }
                setNewUser()
                setAllHoliday()
                setSelectdHoliday()
                setRemaningHoliday()

            }
        } catch (error) {
            console.log("error from get user timesheet api ", error);
            holidayErrorMessage()
        }
    }

    useEffect(() => {

        const holidayNames = holiday.map(p => p.name);
        const filteredPolicies = allHoliday.filter(p => !holidayNames.includes(p.name));
        setRemaningHoliday(filteredPolicies);

    }, [holiday, allHoliday])

    useEffect(() => {
        getUserHoliday()
        getAllHoliday()
    }, [newUser])



    const holidayErrorMessage = () => {
        toast.error("Something went wrong!!", {
            position: 'top-right',
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    };

    const holidaySuccessMessage = () => {
        toast.success("Holidays has been updated!!", {
            position: 'top-right',
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    };

    return (

        <Container fluid className="dash3" style={{ padding: "0" }}>


            {/* Row 2 */}
            <Row style={{ background: "#F1F1F1" }}>
                < ToastContainer />
                <Col md={12}>
                    <div
                        style={
                            editTab ? {
                                minWidth: "886px",
                                margin: "0px",
                                padding: "20px",
                                borderRadius: "10px",
                                minHeight: "500px",
                                background: "white",
                                boxShadow: "none"

                            }
                                : {
                                    minWidth: "886px",
                                    marginTop: "22px",
                                    padding: "20px",
                                    borderRadius: "10px",
                                    minHeight: "500px",
                                    background: "white",
                                    boxShadow: "none"
                                }}
                        className="adduser">
                        <div style={{ paddingTop: "30px", justifyContent: "space-between" }} className="adduser-header">
                            <div style={{ display: "flex", gap: "10px" }}>
                                <button onClick={() => setActivTab("user")} style={{ background: "white" }} className="btn1">User Info</button>
                                <button onClick={() => setActivTab("policy")} style={{ background: "white" }} className="btn1">Policy Info</button>
                                <button className="btn1">Holiday Info</button>
                            </div>

                            <div >
                                <button style={!editTab ? { display: "none" } : null} onClick={() => handleClose()} className="btn1">Close</button>
                            </div>
                        </div>

                        <div style={{ padding: "20px" }}>
                            <div className="user-tab-policy" >
                                <p>Holiday Name</p>
                                <p>Occurs Yearly <span style={{ marginLeft: "30px" }}>Delete</span></p>
                            </div>
                            {holiday?.map((item, index) => {
                                return (
                                    <div style={item?.add === false ? { display: "none" } : null} className="user-tab-policy" key={index}>
                                        <p> {item?.name} </p>
                                        <p>{item?.occursYearly ? "Yes" : "No"}
                                            <i onClick={() => removeHolidayByName(item?.name)}
                                                style={{ color: 'red', fontSize: '20px', cursor: 'pointer', marginLeft: "110px" }}
                                                className="fa-sharp fa-solid fa-trash userIconsHover"
                                            ></i>
                                        </p>

                                    </div>
                                )
                            })}

                            <div style={{ margin: "20px 70px" }} className="role-border mb-5">
                            <label className="lable_bold" style={{ alignItems: "center" }} htmlFor="">
                                    Holiday
                                    <span
                                        style={{
                                            color: "blue",
                                            fontSize: "14px",
                                            textDecoration: "underline",
                                            fontWeight: "400",
                                            marginLeft: "14px",
                                            cursor: "pointer"
                                        }}
                                        onClick={() => { navigate("/holiday") }}
                                    >Create Holiday</span>
                                </label>
                                <Form.Select
                                    required
                                    onChange={handleAddHoliday}
                                    autocomplete="off"
                                    name="role"
                                    className="role"
                                    aria-label="Default select example"
                                    value={selectdHoliday}
                                >
                                    <option value="">
                                        select
                                    </option>
                                    <option value="selectAll">Select All</option>
                                    {remaningHoliday?.map((item, index) => {
                                        return (
                                            <option key={index} value={item?.name}>
                                                {item?.name}
                                            </option>
                                        )
                                    })}
                                </Form.Select>


                            </div>
                            <div className="adduserBtn ">
                                <button onClick={(e) => handleSaveData(e, false)} className="btn5">
                                    Save
                                </button>
                            </div>
                        </div>

                    </div>
                </Col>
            </Row>


        </Container>

    );
};

export default UserHolidayDetails;
