// import { useSelector } from 'react-redux';

// if data is availabe inside a localStorage than it returns true otherwise false
const adminloginInfo = JSON.parse(localStorage.getItem('adminLoginInfo'));
const userLoginInfo = JSON.parse(localStorage.getItem('userLoginInfo'));

if (adminloginInfo) {
  var homeData = adminloginInfo
}
else if (userLoginInfo) {
  var homeData = userLoginInfo
}

export const isAuthenticatedForAddUser = () => {


  const adminloginInfo = JSON.parse(localStorage.getItem('adminLoginInfo'));
  const userLoginInfo = JSON.parse(localStorage.getItem('userLoginInfo'));

  if (adminloginInfo) {
    var homeData = adminloginInfo
  }
  else if (userLoginInfo) {
    var homeData = userLoginInfo
  }

  if (typeof window == 'undefined') {
    return false;
  }
  else if (!homeData) {
    return false;
  } else if (homeData.accessibility.employe == true) {
    return true;
  } else {
    return false;
  }
};

export const isAuthenticatedForMyTimeInAdmin = () => {


  const adminloginInfo = JSON.parse(localStorage.getItem('adminLoginInfo'));
  const userLoginInfo = JSON.parse(localStorage.getItem('userLoginInfo'));

  if (adminloginInfo) {
    var homeData = adminloginInfo
  }
  else if (userLoginInfo) {
    var homeData = userLoginInfo
  }


  if (typeof window == 'undefined') {
    return false;
  }
  else if (!homeData) {
    return false;
  } else if (homeData.accessibility.myTimesheets == true) {
    return true;
  } else {
    return false;
  }
};

export const isAuthenticatedForMyTimeInUser = () => {

  const adminloginInfo = JSON.parse(localStorage.getItem('adminLoginInfo'));
  const userLoginInfo = JSON.parse(localStorage.getItem('userLoginInfo'));

  if (adminloginInfo) {
    var homeData = adminloginInfo
  }
  else if (userLoginInfo) {
    var homeData = userLoginInfo
  }


  if (typeof window == 'undefined') {
    return false;
  }
  else if (!homeData) {
    return false;
  } else if (homeData.accessibility.myTimesheets == true) {
    return true;
  } else {
    return false;
  }
};

export const isAuthenticatedForTask = () => {

  const adminloginInfo = JSON.parse(localStorage.getItem('adminLoginInfo'));
  const userLoginInfo = JSON.parse(localStorage.getItem('userLoginInfo'));

  if (adminloginInfo) {
    var homeData = adminloginInfo
  }
  else if (userLoginInfo) {
    var homeData = userLoginInfo
  }

  if (typeof window == 'undefined') {
    return false;
  }
  else if (!homeData) {
    return false;
  } else if (homeData.accessibility.task == true) {
    return true;
  } else {
    return false;
  }
};

export const isAuthenticatedForProject = () => {


  const adminloginInfo = JSON.parse(localStorage.getItem('adminLoginInfo'));
  const userLoginInfo = JSON.parse(localStorage.getItem('userLoginInfo'));

  if (adminloginInfo) {
    var homeData = adminloginInfo
  }
  else if (userLoginInfo) {
    var homeData = userLoginInfo
  }

  if (typeof window == 'undefined') {
    return false;
  }
  else if (!homeData) {
    return false;
  } else if (homeData.accessibility.project == true) {
    return true;
  } else {
    return false;
  }
};

export const isAuthenticatedForUserProfile = () => {

  const adminloginInfo = JSON.parse(localStorage.getItem('adminLoginInfo'));
  const userLoginInfo = JSON.parse(localStorage.getItem('userLoginInfo'));

  if (adminloginInfo) {
    var homeData = adminloginInfo
  }
  else if (userLoginInfo) {
    var homeData = userLoginInfo
  }

  if (typeof window == 'undefined') {
    return false;
  }
  else if (!homeData) {
    return false;
  } else if (homeData.accessibility.profile == true) {
    return true;
  } else {
    return false;
  }
};

export const isAuthenticatedForAdminProfile = () => {

  const adminloginInfo = JSON.parse(localStorage.getItem('adminLoginInfo'));
  const userLoginInfo = JSON.parse(localStorage.getItem('userLoginInfo'));

  if (adminloginInfo) {
    var homeData = adminloginInfo
  }
  else if (userLoginInfo) {
    var homeData = userLoginInfo
  }

  if (typeof window == 'undefined') {
    return false;
  }
  else if (!homeData) {
    return false;
  } else if (homeData.accessibility.profile == true) {
    return true;
  } else {
    return false;
  }
};

export const isAuthenticatedForUserExpanse = () => {

  const adminloginInfo = JSON.parse(localStorage.getItem('adminLoginInfo'));
  const userLoginInfo = JSON.parse(localStorage.getItem('userLoginInfo'));

  if (adminloginInfo) {
    var homeData = adminloginInfo
  }
  else if (userLoginInfo) {
    var homeData = userLoginInfo
  }

  if (typeof window == 'undefined') {
    return false;
  }
  else if (!homeData) {
    return false;
  } else if (homeData.accessibility.myExpenses == true) {
    return true;
  } else {
    return false;
  }
};

export const isAuthenticatedForAdminExpanse = () => {

  const adminloginInfo = JSON.parse(localStorage.getItem('adminLoginInfo'));
  const userLoginInfo = JSON.parse(localStorage.getItem('userLoginInfo'));

  if (adminloginInfo) {
    var homeData = adminloginInfo
  }
  else if (userLoginInfo) {
    var homeData = userLoginInfo
  }
  console.log("homeData======================>", homeData)
  if (typeof window == 'undefined') {
    return false;
  }
  else if (!homeData) {
    return false;
  } else if (homeData.accessibility.myExpenses == true) {
    return true;
  } else {
    return false;
  }
};

export const isAuthenticatedForDashboard = () => {

  const adminloginInfo = JSON.parse(localStorage.getItem('adminLoginInfo'));
  const userLoginInfo = JSON.parse(localStorage.getItem('userLoginInfo'));

  if (adminloginInfo) {
    var homeData = adminloginInfo
  }
  else if (userLoginInfo) {
    var homeData = userLoginInfo
  }

  if (typeof window == 'undefined') {
    return false;
  }
  else if (!homeData) {
    return false;
  }
  else if (homeData.accessibility.dashboard === true) {
    return true;
  } else {
    return false;
  }
};


export const isAuthenticatedForTimesheetAprove = () => {

  const adminloginInfo = JSON.parse(localStorage.getItem('adminLoginInfo'));
  const userLoginInfo = JSON.parse(localStorage.getItem('userLoginInfo'));

  if (adminloginInfo) {
    var homeData = adminloginInfo
  }
  else if (userLoginInfo) {
    var homeData = userLoginInfo
  }

  if (typeof window == 'undefined') {
    return false;
  }
  else if (!homeData) {
    return false;
  }
  else if (homeData.accessibility.timesheets === true) {
    return true;
  } else {
    return false;
  }
};


export const isAuthenticatedForExpenseAprove = () => {

  const adminloginInfo = JSON.parse(localStorage.getItem('adminLoginInfo'));
  const userLoginInfo = JSON.parse(localStorage.getItem('userLoginInfo'));

  if (adminloginInfo) {
    var homeData = adminloginInfo
  }
  else if (userLoginInfo) {
    var homeData = userLoginInfo
  }

  if (typeof window == 'undefined') {
    return false;
  }
  else if (!homeData) {
    return false;
  }
  else if (homeData.accessibility.expenses === true) {
    return true;
  } else {
    return false;
  }
};



export const isAuthenticatedForReports = () => {

  const adminloginInfo = JSON.parse(localStorage.getItem('adminLoginInfo'));
  const userLoginInfo = JSON.parse(localStorage.getItem('userLoginInfo'));

  if (adminloginInfo) {
    var homeData = adminloginInfo
  }
  else if (userLoginInfo) {
    var homeData = userLoginInfo
  }

  if (typeof window == 'undefined') {
    return false;
  }
  else if (!homeData) {
    return false;
  }
  else if (homeData.accessibility.reports === true) {
    return true;
  } else {
    return false;
  }
};



export const isAuthenticatedForRole = () => {

  const adminloginInfo = JSON.parse(localStorage.getItem('adminLoginInfo'));
  const userLoginInfo = JSON.parse(localStorage.getItem('userLoginInfo'));

  if (adminloginInfo) {
    var homeData = adminloginInfo
  }
  else if (userLoginInfo) {
    var homeData = userLoginInfo
  }

  if (typeof window == 'undefined') {
    return false;
  }
  else if (!homeData) {
    return false;
  }
  else if (homeData.accessibility.role === true) {
    return true;
  } else {
    return false;
  }
};



export const isAuthenticatedForSync = () => {

  const adminloginInfo = JSON.parse(localStorage.getItem('adminLoginInfo'));
  const userLoginInfo = JSON.parse(localStorage.getItem('userLoginInfo'));

  if (adminloginInfo) {
    var homeData = adminloginInfo
  }
  else if (userLoginInfo) {
    var homeData = userLoginInfo
  }

  if (typeof window == 'undefined') {
    return false;
  }
  else if (!homeData) {
    return false;
  }
  else if (homeData.accessibility.sync === true) {
    return true;
  } else {
    return false;
  }
};

export const isAdminChatAccount = () => {

  const adminChatAccountInfo = JSON.parse(localStorage.getItem('adminChatAccountInfo'));


  if (typeof window == 'undefined') {
    return false;
  }
  else if (adminChatAccountInfo?.token) {
    return true;
  }
  else if (!adminChatAccountInfo) {
    return false;
  } else {
    return false;
  }
};

export const isAdminDetailsTrackAccount = () => {

  const adminDetailsAccountInfo = JSON.parse(localStorage.getItem('adminDetailsAccountInfo'));


  if (typeof window == 'undefined') {
    return false;
  }
  else if (adminDetailsAccountInfo?.token) {
    return true;
  }
  else if (!adminDetailsAccountInfo) {
    return false;
  } else {
    return false;
  }
};




