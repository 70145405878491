import React from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const UserPdf = ({ userTimesheetInfo, selectedRowIds }) => {

  if (selectedRowIds) {
    var filteredData = userTimesheetInfo.filter(item => selectedRowIds.includes(item._id));
  } else {
    var filteredData = userTimesheetInfo;
  }

  filteredData.sort((a, b) => a.date.localeCompare(b.date));

  const generatePDF = () => {
    const pdf = new jsPDF();

    // Exclude specific fields from headers and data
    const excludeFields = ['_id', 'userID', 'companyID', 'createdAt', 'updatedAt', '__v', 'sendToQb', 'userName', 'fromQb', 'photo', 'description', 'merchant', 'expenseReport', 'paidBy', 'name', 'rejectReason', 'demo'];
    const headers = Object.keys(filteredData[0]).filter(key => !excludeFields.includes(key));
    const tableData = filteredData.map(entry =>
      headers.map(header => entry[header])
    );

    const userName = filteredData[0]?.userName;
    const startDate = filteredData[0]?.date;
    const endDate = filteredData[filteredData.length - 1]?.date;

    const pageWidth = pdf.internal.pageSize.getWidth();

    // Centering the header text
    const centerText = (text, y) => {
      const textWidth = pdf.getTextWidth(text);
      const textX = (pageWidth - textWidth) / 2;
      pdf.text(text, textX, y);
    };

    // Header format
    pdf.setFontSize(22);
    centerText('Timesheets Summary', 15);
    pdf.setFontSize(16);
    centerText(`${userName}`, 25);
    pdf.setFontSize(14);
    centerText(`${startDate} - ${endDate}`, 32);

    pdf.autoTable({
      head: [headers],
      body: tableData,
      startY: 45
    });

    // Save the PDF or open in a new tab
    pdf.save('timesheets.pdf');
  };

  return (
    <div>
      <button className='button-container-button' onClick={generatePDF}>PDF</button>
    </div>
  );
};

export default UserPdf;
