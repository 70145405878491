import React from 'react'
import './Loder.css'

const Loder = () => {
  return (
    <div>
      <div style={{ position: "fixed", top: "45%", left: "50%", zIndex: "1" }}>
        {/* <div class="time-6"></div> */}
        <div class="time-5"></div>
      </div>
    </div>
  )
}

export default Loder