import { useEffect, useState } from 'react';
// import OutlinedInput from '@mui/material/OutlinedInput';
// import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};



export default function MultiSelect({ user, handleMultiChange, disable, reset, setReset }) {

  const [personName, setPersonName] = useState([]);

  useEffect(() => {
    if (reset) {
      setPersonName([]);
      setReset(false)
    }
  }, [reset]);


  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    handleMultiChange(value);
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };
  console.log(personName);
  return (
    <>
      <FormControl sx={{ m: 1, width: 250 }} style={{ margin: "0px" }}>
        {/* <InputLabel id="demo-multiple-checkbox-label">Tag</InputLabel> */}
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={personName}
          onChange={handleChange}
          className='project_select'
          // input={<OutlinedInput label="Tag" />}
          renderValue={(selected) => {
            const nameArray = [];
            selected.map((s) => {
              const { name } = s;
              nameArray.push(name);
            });

            return nameArray.length === 0 ? "Select" : nameArray.join(', ')
          }}
          MenuProps={MenuProps}
          style={{ height: "38px", margin: "0px", border: "none" }}
          disabled={disable === true ? true : false}
          sx={disable === true ? { background: "#e9ecef" } : null}
        >
          {user?.map((u) => (
            <MenuItem key={u._id} value={u}>
              <Checkbox checked={personName.indexOf(u) > -1} />
              <ListItemText primary={u.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
}