import React, { useContext } from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { MyContext } from '../../../context/MyProvider';

const TimeOffPDF = ({ companyName }) => {
  const { reportFilteredData } = useContext(MyContext);

  const generatePdf = () => {
    if (!reportFilteredData || reportFilteredData.length === 0) {
      // Handle the case when there is no data
      return;
    }
    const doc = new jsPDF();

    // Define columns and rows
    const excludeFields = ['_id', 'userID', 'companyID', 'createdAt', 'updatedAt', '__v', 'sendToQb', 'fromQb', 'photo', 'description', 'merchant', 'expenseReport', 'paidBy', 'name'];
    const columns = Object.keys(reportFilteredData[0]).filter(key => !excludeFields.includes(key));
    const rows = reportFilteredData.map(item => columns.map(col => item[col]));

    const startDate = reportFilteredData[0]?.Date;
    const endDate = reportFilteredData[reportFilteredData.length - 1]?.Date;

    const pageWidth = doc.internal.pageSize.getWidth();
    const exportDate = new Date().toLocaleString();  // Exported date and time

    // Centering the header text
    const centerText = (text, y) => {
      const textWidth = doc.getTextWidth(text);
      const textX = (pageWidth - textWidth) / 2;
      doc.text(text, textX, y);
    };

    // Header format
    doc.setFontSize(22);
    centerText('Timesheets Summary', 15);
    doc.setFontSize(16);
    centerText(`${companyName}`, 25);
    doc.setFontSize(14);
    centerText(`${startDate} - ${endDate}`, 32);

    // Create table using autoTable method
    doc.autoTable({
      head: [columns],
      body: rows,
      startY: 45,
      styles: {
        fontSize: 8, // Set font size for the table
        cellPadding: 2, // Set padding for cells
      },
      columnStyles: {
        0: { fontStyle: 'bold', cellWidth: 20 }, // Customize style and width of the first column
        1: { cellWidth: 20 },
        2: { cellWidth: 30 },
        3: { cellWidth: 15 },
        4: { cellWidth: 20},
        5: { cellWidth: 25 },
        6: { cellWidth: 25 },
        7: { cellWidth: 25 },
      },
      didDrawPage: (data) => {
        // Footer content
        const pageNumberText = `Page ${data.pageNumber}`;
        const footerY = doc.internal.pageSize.height - 10;

        // Add footer text
        doc.setFontSize(10);

        // Left corner
        doc.text(pageNumberText, 10, footerY);

        // Right corner
        const rightText = `Exported on ${exportDate}`;
        const rightTextWidth = doc.getTextWidth(rightText);
        const rightTextX = pageWidth - rightTextWidth - 10;
        doc.text(rightText, rightTextX, footerY);
      },
    });

    // Save the PDF
    doc.save(`${companyName}.pdf`);
  };

  return (
    <div style={{ margin: "0px 28px" }}>
      <button className="csv_button" onClick={generatePdf}>Export PDF</button>
    </div>
  );
};

export default TimeOffPDF;
