import React, { useContext } from "react";
import "./AddUser.css";
import { Col, Form, Container, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import signupImg1 from "../../img/Vector.png";
import signupImg2 from "../../img/user2.png";
import { useEffect, useState } from "react";
import AllUsersTable from "../../components/AllUsersTable.js";
import axios from "axios";
import AdminLayout from "../../hoc/AdminLayout";
import { MyContext } from "../../context/MyProvider";
import { ToastContainer, toast } from 'react-toastify';
import ChatAfterLogin from "../../components/chat/ChatAfterLogin";
import ProfileComponent from "../../components/ProfileComponent";
import ImportUser from "../../components/import/ImportUser.js";

const ManageUser = () => {
    const navigate = useNavigate();
    const { sideBarStatus, setSideBarStatus } = useContext(MyContext);

    const handleClick = () => {
        if (sideBarStatus == true) {
            setSideBarStatus(false);
        } else {
            setSideBarStatus(true);
        }
    };

    const screenWidth = window.innerWidth;
    if (screenWidth >= 840) {
        var forPhoneScreenNoDisplay = true;
    } else {
        var forPhoneScreenNoDisplay = false;
    }

    const adminloginInfo = JSON.parse(localStorage.getItem("adminLoginInfo"));
    const userLoginInfo = JSON.parse(localStorage.getItem("userLoginInfo"));


    if (adminloginInfo) {
        var companyID = adminloginInfo?._id;
        var token = adminloginInfo?.token
    } else if (userLoginInfo) {
        var companyID = userLoginInfo?.companyID;
        var token = userLoginInfo?.token
    }

    let allTheEmployee = [];

    const [allUser, setAllUser] = useState([]);
    const [allManager, setAllManager] = useState([]);
    const [allSubAdmin, setAllSubAdmin] = useState([]);
    const [superAdmin, setSuperAdmin] = useState([]);
    const [newSubAdmin, setNewSubAdmin] = useState();
    const [newManager, setNewManager] = useState();
    const [newUser, setNewUser] = useState();

    const [combinedArray, setCombinedArray] = useState([])
    const [usersAndManagers, setUsersAndManagers] = useState([])
    const [combinedArrayForFilter, setCombinedArrayForFilter] = useState([])

    const [formDataUser, setFromDataUser] = useState({
        companyID: companyID,
    });
    console.log("formDataUser====>", formDataUser);

    // config

    const config = {
        headers: {
            Accept: "application/json",
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`
        },
    };



    console.log(formDataUser);



    const getAllUser = async () => {
        try {
            const { data } = await axios.get(
                `https://app.timetraker.com/backend/api/all-users/?companyID=${companyID}`,
                config
            );
            allTheEmployee.push(...data.allUsers);
            setAllUser(data.allUsers);
        } catch (error) {
            console.log("error from get all user api", error);
        }
    };

    const getAllManager = async () => {
        try {
            const { data } = await axios.get(
                `https://app.timetraker.com/backend/api/all-manager/?companyID=${companyID}`,
                config
            );
            allTheEmployee.push(...data.allManager);
            setAllManager(data.allManager);
        } catch (error) {
            console.log("error from get all user api", error);
        }
    };

    const getAllSubAdmin = async () => {
        try {
            const { data } = await axios.get(
                `https://app.timetraker.com/backend/api/all-sub-admin/?companyID=${companyID}`,
                config
            );
            allTheEmployee.push(...data.allSubAdmin);
            setAllSubAdmin(data.allSubAdmin);
        } catch (error) {
            console.log("error from get all user api", error);
        }
    };

    const getSuperAdmin = async () => {
        try {
            const { data } = await axios.get(
                `https://app.timetraker.com/backend/api/super-admin/?companyID=${companyID}`,
                config
            );
            allTheEmployee.push(...data.superAdmin);
            setSuperAdmin(data.superAdmin);
        } catch (error) {
            console.log("error from get all user api", error);
        }
    };

    const handleChangeRole = (e) => {
        const data = combinedArrayForFilter
        const filteredData = data.filter((item) => item.role === e.target.value)
        setCombinedArray(filteredData)
        if (e.target.value == "") {
            setCombinedArray(data)
        }
    }

    useEffect(() => {
        const newcombinedArray = allUser.concat(allManager, allSubAdmin, superAdmin);
        const newcombinedArrayForUserAndManager = allUser.concat(allManager);
        setUsersAndManagers(newcombinedArrayForUserAndManager)
        setCombinedArray(newcombinedArray)
        setCombinedArrayForFilter(newcombinedArray)
    }, [allUser, allManager, allSubAdmin, superAdmin])

    const handleDataFromChild = (data) => {
        // Do something with the data in the parent component
        setNewSubAdmin(data);
        setNewManager(data);
        setNewUser(data);
    };

    useEffect(() => {
        getAllUser();
        getAllManager();
        getAllSubAdmin();
        getSuperAdmin()
        if (!forPhoneScreenNoDisplay) {
            setSideBarStatus(false);
        }
    }, []);

    useEffect(() => {
        getAllUser();
        getAllManager();
        getAllSubAdmin();
        getSuperAdmin()
    }, [newUser, newManager, newSubAdmin]);

    const adminAddNotfy = () => {
        toast.success('Admin has been added', {
            position: 'top-right',
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    };


    const managerAddNotfy = () => {
        toast.success('Manager has been added', {
            position: 'top-right',
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    };


    const userAddNotfy = () => {
        toast.success('User has been added', {
            position: 'top-right',
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    };

    return (
        <AdminLayout>
            < ToastContainer />
            <div
                className={sideBarStatus ? "content_right_dashboard" : "none"}
                fluid
                style={
                    (sideBarStatus == true) & (forPhoneScreenNoDisplay == false)
                        ? { display: "none" }
                        : { display: "block" }
                }
            >
                <div
                    className="hamburgar"
                    style={sideBarStatus ? { display: "none" } : { display: "block" }}
                >
                    <i onClick={handleClick} className="fas fa-bars"></i>
                </div>
                <Container fluid className="dash3" style={{ padding: "0" }}>
                    {/* Row 1 */}
                    <Row>
                        <Col md={12} className="adduser-container">
                            <div className="adduser-top">
                                <div className="adduser-header1">
                                    <h5
                                        style={
                                            sideBarStatus == true
                                                ? { paddingLeft: "10px", paddingTop: "2px" }
                                                : { paddingLeft: "60px", paddingTop: "4px" }
                                        }
                                    >
                                        Manage Users
                                    </h5>
                                </div>

                                <div className="adduser-header2">
                                    <ProfileComponent />
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <div className="button-container" style={{ display: "flex", justifyContent: "end", marginTop: "45px", marginRight: "50px" }}>
                        <Form.Select
                            className="input-menu-focus"
                            style={{ cursor: 'pointer', width: "150px" }}
                            name="employee"
                            aria-label="Default select example"
                            onChange={handleChangeRole}

                        >
                            <option value="">Role</option>

                            <option value="user">
                                User
                            </option>
                            <option value="sub-admin">
                                Admin
                            </option>
                            <option value="manager">
                                Manager
                            </option>

                        </Form.Select>

                        <ImportUser className='button-container-button' />
                    </div>
                    {/* Row 3 */}
                    <Row style={{ background: "#F1F1F1", marginTop: "50px" }}>
                        <Col md={12}>
                            <div className="admin-container">

                                <AllUsersTable
                                    allUser={combinedArray}
                                    usersAndManagers={usersAndManagers}
                                    handleDataFromChild={handleDataFromChild}
                                />
                            </div>
                        </Col>
                    </Row>

                </Container>
            </div>



        </AdminLayout>
    );
};

export default ManageUser;
