

import * as React from 'react';
import { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import axios from 'axios';
import "./TableDesign.css"
import { DialogActions, DialogContent, DialogTitle, Dialog, Button } from '@mui/material';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Form } from "react-bootstrap";
import Checkbox from '@mui/material/Checkbox';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import { blue } from '@mui/material/colors';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import InfoIcon from '@mui/icons-material/Info';
import { Typography } from "@mui/material";
import ExpenseUserPdf from './ExpenseExport/ExpenseUserPdf';
import ExpenseUserCsv from './ExpenseExport/ExpenseUserCsv';
import ExpenseUserExcel from './ExpenseExport/ExpenseUserExcel';
import Swal from 'sweetalert2'

const columns = [
    { id: 'date', label: 'Date', minWidth: 120 },
    { id: 'expense', label: 'Type', minWidth: 100 },
    { id: 'state', label: 'Status', minWidth: 100 },
    { id: 'project', label: 'Project', minWidth: 100 },
    { id: 'amount', label: 'Amount', minWidth: 100 },
    { id: 'description', label: 'Description', minWidth: 100 },
    { id: 'billable', label: 'Billable', minWidth: 110 },
    { id: 'check', label: 'Check', minWidth: 100 },
    { id: 'more', label: 'More', minWidth: 100 },
];


// temporory data

const data = [
    {
        date: '01-12-2023',
        type: 'Meals & Entertainment',
        status: 'submitted',
        project: 'timesheet',
        amount: '5000',
        description: 'Inner pages design',
        billable: 'yes',
    },
    {
        date: '01-12-2023',
        type: 'Parking',
        status: 'hold',
        project: 'buzzmails landing',
        amount: '6000',
        description: 'web development',
        billable: 'yes',
    }

];

export default function StickyHeadTable({ userExpenseInfo, handleDataFromChild, selectedRowIds, setSelectedRowIds, setUserExpenseInfo }) {


    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const adminloginInfo = JSON.parse(localStorage.getItem("adminLoginInfo"));
    const userLoginInfo = JSON.parse(localStorage.getItem("userLoginInfo"));
    if (adminloginInfo) {
        var companyID = adminloginInfo?._id;
        var token = adminloginInfo?.token
        var userID = adminloginInfo._id;
        var userName = adminloginInfo.name;
    }
    if (userLoginInfo) {
        var companyID = userLoginInfo?.companyID;
        var token = userLoginInfo?.token
        var userID = userLoginInfo._id;
        var userName = userLoginInfo.name;
    }


    //config 

    const config = {
        headers: {
            Accept: 'application/json',
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    };





    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [expenseId, setExpenseId] = React.useState('')

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };



    const deleteData = async (itemID) => {
        handleCloseUserMenu()

        Swal.fire({
            title: "Are you sure?",
            text: "You want to delete this Expense",
            // icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then(async (result) => {
            if (result.isConfirmed) {

                try {
                    const { deletedExpense } = await axios.delete(`https://app.timetraker.com/backend/api/delete-expense/?expenseID=${itemID}`, config)
                    console.log(deletedExpense)
                    TimesheetDeleteNotfy()
                    handleDataFromChild(deletedExpense)
                } catch (error) {
                    console.log("error", error)
                }
            }
        })
    }

    const submitExpense = async (itemID) => {
        handleCloseUserMenu()

        Swal.fire({
            title: "Are you sure?",
            text: "You want to submit this Expense",
            // icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, submit it!"
        }).then(async (result) => {
            if (result.isConfirmed) {


                try {
                    const { data } = await axios.get(`https://app.timetraker.com/backend/api/submit-expense/?expenseID=${itemID}`, config)
                    handleDataFromChild(data)
                    TimesheetSubmitNotfy()
                } catch (error) {
                    console.log("error", error)
                }

            }
        })
    }

    const getImage = async (itemID) => {
        setExpenseId(itemID)
        handleClickOpen()
        handleCloseUserMenu()
    }


    const [reversSort, setReversSort] = useState(true)
    const [sortedColumn, setSortedColumn] = useState(null);


    const handleChangeSort = (field) => {
        const sortedData = [...userExpenseInfo].sort((a, b) => {

            const aValue = a[field];
            const bValue = b[field];

            let comparison = 0;
            if (field === 'date') {
                comparison = new Date(aValue) - new Date(bValue);
            } else if (typeof aValue === 'string') {
                comparison = aValue.localeCompare(bValue);
            } else if (typeof aValue === 'number') {
                comparison = aValue - bValue;
            } else if (typeof aValue === 'boolean') {
                // Handle boolean fields
                comparison = (aValue === bValue) ? 0 : (aValue ? 1 : -1);
            }

            return reversSort ? comparison : -comparison;
        });

        setUserExpenseInfo(sortedData);
        setReversSort(prevToggle => !prevToggle);
        setSortedColumn(field)
    };

    const handleCheck = (_id) => {
        setSelectedRowIds(prevSelectedRowIds => {
            if (prevSelectedRowIds.includes(_id)) {
                return prevSelectedRowIds.filter(id => id !== _id);
            } else {
                return [...prevSelectedRowIds, _id];
            }
        });
    };

    const [allCheck, setAllCheck] = useState(false)

    const handleChnageAllCheck = () => {
        if (allCheck === false) {
            setAllCheck(true)
            if (rowsPerPage > 0) {
                userExpenseInfo?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => {

                    if (item?.state === 'hold') {
                        setSelectedRowIds(prevSelectedRowIds => {
                            return [...prevSelectedRowIds, item?._id];
                        })
                    }
                })
            }
        } else {
            setAllCheck(false)
            setSelectedRowIds([])
        }
    }



    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const [moreIconData, setMoreIconData] = useState()

    const handleOpenUserMenu = (event, item) => {
        setAnchorElUser(event.currentTarget);
        setMoreIconData(item)
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const [anchorElUser1, setAnchorElUser1] = React.useState(null);
    const [rejectReason, setRejectReason] = useState("")

    const handleOpenUserMenu1 = (event, data) => {
        setAnchorElUser1(event.currentTarget);
        setRejectReason(data)
    };


    const handleCloseUserMenu1 = () => {
        setAnchorElUser1(null);
    };

    // edit section

    const [selectProject, setSelectProject] = useState("");
    const [selectExpense, setSelectExpense] = useState("");
    const [selectPaidBy, setSelectPaidBy] = useState("");


    const [open2, setOpen2] = React.useState(false);

    const handleClickOpenEdit = () => {
        setOpen2(true);
    };

    const handleCloseEdit = () => {
        setOpen2(false);
    };

    const [formData, setFormData] = useState({
        userID: userID,
        companyID: companyID,
        date: "",
        hours: "",
        project: "",
        task: "",
    });
    console.log("formData", formData);

    const [dataNeedToEdit, setDataNeedToEdit] = useState()


    const handleDailogBoxOpen = async (item) => {
        setDataNeedToEdit({
            _id: item._id,
            description: item.description,
            project: item.project,
            date: item.date,
            amount: item.amount,
            expense: item.expense,
            paidBy: item.paidBy
        })
        setFormData({
            _id: item._id,
            description: item.description,
            project: item.project,
            date: item.date,
            amount: item.amount,
            expense: item.expense,
            paidBy: item.paidBy
        })
        setSelectPaidBy(item.paidBy)
        setSelectExpense(item.expense)
        setSelectProject(item.project)
        handleCloseUserMenu()
        handleClickOpenEdit()
    }


    const [projectInfo, setProjectInfo] = useState([]);
    const [chartOfAccount, setChartOfAccount] = useState([]);


    // get all project

    const getAllProject = async () => {
        try {
            const { data } = await axios.get(
                `https://app.timetraker.com/backend/api/user-project/?userID=${userID}`,
                config
            );
            setProjectInfo(data.projects);
        } catch (error) {
            console.log("error", error)
        }
    };

    const getAllChartOfAccount = async () => {
        try {
            const { data } = await axios.get(
                `https://app.timetraker.com/backend/api/get-chart-of-account-for-expense/?companyID=${companyID}`,
                config
            );
            setChartOfAccount(data.chartOfAccount);
        } catch (error) {
            console.log(error);
        }
    };



    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            handleCloseEdit();
            const { data } = await axios.post(
                `https://app.timetraker.com/backend/api/edit-expense`,
                formData,
                config
            );

            if (data) {
                handleDataFromChild(data)
                TimesheetEditNotfy()
            }
        } catch (error) {
            console.log("error from post timesheet api", error);
        }
    };


    const TimesheetSubmitNotfy = () => {
        toast.success('Your expense has been submited', {
            position: 'top-right',
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    };


    const TimesheetEditNotfy = () => {
        toast.success('Your expense has been updated', {
            position: 'top-right',
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    };

    const TimesheetDeleteNotfy = () => {
        toast.success('Your expense has been deleted', {
            position: 'top-right',
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    };


    useEffect(() => {
        getAllProject()
        getAllChartOfAccount()
    }, [])

    const [open3, setOpen3] = React.useState(false);
    const [selectedPrint, setSelectedPrint] = useState([])


    const handleClickOpen3 = (item) => {
        handleCloseUserMenu()
        setOpen3(true);
        setSelectedPrint([item])
    };

    const handleClose3 = () => {
        setOpen3(false);
    };




    return (
        <Paper sx={{ width: '100%', overflow: 'auto' }}>
            <TableContainer>
                <Table stickyHeader aria-label="sticky table" style={{ overflow: 'hidden' }}>
                    <TableHead >
                        <TableRow >
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth, background: '#04542C', color: '#fff', textAlign: 'center' }}>
                                    {column.label === "Check" ?
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            {column.label}
                                            <Checkbox
                                                sx={{
                                                    color: '#76ff03',
                                                    '&.Mui-checked': {
                                                        color: '#76ff03',
                                                    },
                                                }}
                                                onChange={handleChnageAllCheck}
                                            />
                                        </div>
                                        : column.label === "More" ? <div> {column.label} </div> :
                                            <div
                                                onClick={() => handleChangeSort(column.id)}
                                                style={{ display: "flex", cursor: "pointer" }}>
                                                <ArrowDropDownCircleIcon
                                                    style={{
                                                        transform: column.id === sortedColumn && !reversSort ? "rotate(180deg)" : "rotate(0deg)",
                                                        margin: "0px 10px 0px 17px",
                                                    }}
                                                />
                                                {column.label}
                                            </div>
                                    }
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody style={{ overflow: 'hidden', cursor: 'context-menu' }}>
                        {userExpenseInfo
                            ? (rowsPerPage > 0 ? userExpenseInfo.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : userExpenseInfo)?.map((item, index) => {
                                function shortenString(str) {
                                    if (str?.length > 8) {
                                        return str.substring(0, 8) + '...';
                                    }
                                    return str;
                                }
                                return (

                                    <TableRow style={item.state == 'rejected' ? { background: "#ffb2b2b5" } : { background: "white" }} hover role="checkbox" tabIndex={-1} key={index}>
                                        <TableCell style={{ width: '110px', textAlign: 'center' }} >{item?.date}</TableCell>
                                        <TableCell title={item?.expense ? item?.expense : "Empty"} style={{ textAlign: 'center' }}>{shortenString(item?.expense ? item?.expense : "Empty")}</TableCell>
                                        <TableCell align="center">{item?.state === 'rejected' ? <div style={{ display: "flex", alignItems: "center", gap: '5px' }}>{item?.state} <InfoIcon onClick={(event) => handleOpenUserMenu1(event, item.rejectReason)} /></div> : item?.state}</TableCell>
                                        <Menu
                                            sx={{ mt: '30px' }}
                                            id="menu-appbar"
                                            anchorEl={anchorElUser1}
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            open={Boolean(anchorElUser1)}
                                            onClose={handleCloseUserMenu1}
                                        >
                                            <MenuItem style={{ gap: "10px", alignItems: "start", background: "white" }} >
                                                <Typography>{rejectReason}</Typography>
                                            </MenuItem>
                                        </Menu>
                                        <TableCell title={item?.project ? item?.project : "NA"} style={{ textAlign: 'center' }}>{shortenString(item?.project ? item?.project : "NA")}</TableCell>
                                        <TableCell title={item?.amount} style={{ textAlign: 'center' }}>{shortenString(item?.amount)}</TableCell>
                                        <TableCell title={item?.description ? item?.description : "Empty"} style={{ textAlign: 'center' }}>{shortenString(item?.description ? item?.description : "Empty")}</TableCell>
                                        <TableCell style={{ textAlign: 'center', minWidth: '110px' }}>{item?.billable == true ? "Billable" : "Not Billable"}</TableCell>


                                        <TableCell align="center"><Checkbox disabled={item.state !== "hold" ? true : false} checked={selectedRowIds.includes(item._id)}
                                            onChange={() => handleCheck(item._id)}
                                            sx={{
                                                color: blue[800],
                                                '&.Mui-checked': {
                                                    color: blue[600],
                                                },
                                            }} /></TableCell>
                                        <TableCell onClick={(event) => handleOpenUserMenu(event, item)} style={{ cursor: "pointer" }} align="center"><MoreHorizIcon style={{
                                            background: "#e3e3e3d1",
                                            borderRadius: "100px",
                                            height: "22px",
                                            width: "45px"
                                        }} /></TableCell>

                                        <Menu
                                            sx={{ mt: '45px' }}
                                            id="menu-appbar"
                                            anchorEl={anchorElUser}
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            open={Boolean(anchorElUser)}
                                            onClose={handleCloseUserMenu}
                                        >
                                            <MenuItem style={{ gap: "10px", alignItems: "start", background: "white" }} >
                                                <TableCell style={{ borderBottom: "none", borderRight: "1px solid #dddddd" }} align="center">
                                                    <i
                                                        title="Print"
                                                        onClick={() => handleClickOpen3(moreIconData)}
                                                        style={{ color: 'green', paddingLeft: '0px', fontSize: '20px', cursor: 'pointer' }}
                                                        className="fa-solid fa-print userIconsHover"
                                                    ></i>
                                                </TableCell>
                                                <TableCell style={{ textAlign: 'center', borderBottom: "none", borderRight: "1px solid #dddddd" }}>
                                                    <i
                                                        style={{ color: '#134563', fontSize: '21px' }}
                                                        onClick={() => getImage(moreIconData?._id)}
                                                        className="fa-solid fa-paperclip userIconsHover"
                                                    ></i>
                                                </TableCell>

                                                <TableCell style={{ borderBottom: "none", borderRight: "1px solid #dddddd" }} align="center">
                                                    <i
                                                        onClick={() => moreIconData?.state === 'hold' ? submitExpense(moreIconData?._id) : console.log("")}
                                                        style={
                                                            moreIconData?.state === 'hold'
                                                                ? { color: 'green', paddingLeft: '0px', fontSize: '20px', cursor: 'pointer' }
                                                                : { color: 'gray', paddingLeft: '0px', fontSize: '20px', cursor: 'pointer' }
                                                        }
                                                        className="fa-solid fa-arrow-up-from-bracket userIconsHover"
                                                    ></i>
                                                </TableCell>

                                                <TableCell style={{ borderBottom: "none", borderRight: "1px solid #dddddd" }} align="center">
                                                    <i
                                                        onClick={() =>
                                                            moreIconData?.state === 'hold' || moreIconData?.state === 'rejected'
                                                                ? handleDailogBoxOpen(moreIconData)
                                                                : console.log('hello')
                                                        }
                                                        style={
                                                            moreIconData?.state === 'hold' || moreIconData?.state === 'rejected'
                                                                ? { color: 'green', paddingLeft: '0px', fontSize: '20px', cursor: 'pointer' }
                                                                : { color: 'gray', paddingLeft: '0px', fontSize: '20px', cursor: 'pointer' }
                                                        }
                                                        className="fa-solid fa-pen-to-square userIconsHover"
                                                    ></i>
                                                </TableCell>

                                                <TableCell style={{ borderBottom: "none" }} align="center">
                                                    <i
                                                        onClick={() =>
                                                            moreIconData?.state === 'hold' || moreIconData?.state === 'rejected'
                                                                ? deleteData(moreIconData?._id)
                                                                : console.log('you can\'t delete this timesheet')
                                                        }
                                                        style={
                                                            moreIconData?.state === 'hold' || moreIconData?.state === 'rejected'
                                                                ? { color: 'red', fontSize: '20px', cursor: 'pointer' }
                                                                : { color: 'gray', fontSize: '20px', cursor: 'pointer' }
                                                        }
                                                        className="fa-sharp fa-solid fa-trash userIconsHover"
                                                    ></i>
                                                </TableCell>

                                            </MenuItem>
                                        </Menu>














                                        <Dialog
                                            open={open}
                                            onClose={handleClose}
                                            aria-labelledby="alert-dialog-title"
                                            aria-describedby="alert-dialog-description"
                                        >
                                            <DialogTitle id="alert-dialog-title">
                                                {"Receipt Image"}
                                            </DialogTitle>
                                            <DialogContent>
                                                <img style={{ maxWidth: '100%' }} src={`https://app.timetraker.com/backend/api/photo/${expenseId}`} alt='Expense Image' />

                                            </DialogContent>
                                            <DialogActions>
                                                <Button onClick={handleClose}>Close</Button>

                                            </DialogActions>
                                        </Dialog>

                                    </TableRow>
                                );
                            })
                            :
                            data?.map((item, index) => {
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                        <TableCell>{item?.date}</TableCell>
                                        <TableCell>{item?.type}</TableCell>
                                        <TableCell>{item?.status}</TableCell>
                                        <TableCell>{item?.project}</TableCell>
                                        <TableCell>{item?.amount}</TableCell>
                                        <TableCell>{item?.description}</TableCell>
                                        <TableCell>{item?.billable}</TableCell>
                                        <TableCell align="center"><Checkbox disabled={item.state !== "hold" ? true : false} checked={selectedRowIds.includes(item._id)}
                                            onChange={() => handleCheck(item._id)}
                                            sx={{
                                                color: blue[800],
                                                '&.Mui-checked': {
                                                    color: blue[600],
                                                },
                                            }} /></TableCell>
                                        <TableCell onClick={(event) => handleOpenUserMenu(event, item)} style={{ cursor: "pointer" }} align="center"><MoreHorizIcon style={{
                                            background: "#e3e3e3d1",
                                            borderRadius: "100px",
                                            height: "22px",
                                            width: "45px"
                                        }} /></TableCell>

                                        <Menu
                                            sx={{ mt: '45px' }}
                                            id="menu-appbar"
                                            anchorEl={anchorElUser}
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            open={Boolean(anchorElUser)}
                                            onClose={handleCloseUserMenu}
                                        >
                                            <MenuItem style={{ gap: "10px", alignItems: "start", background: "white" }} >
                                                <TableCell style={{ textAlign: 'right' }}>{<i style={{ color: '#134563', fontSize: '25px' }} onClick={() => (getImage(moreIconData?._id))} class="fa-solid fa-paperclip"></i>}</TableCell>
                                                <TableCell align="center"> <i onClick={() => (submitExpense(moreIconData?._id))} style={{ color: 'green', paddingLeft: '30px', fontSize: '20px' }} class="fa-solid fa-arrow-up-from-bracket"></i>   </TableCell>
                                                <TableCell style={{ textAlign: 'right' }}>{<i onClick={() => (deleteData(moreIconData?._id))} style={{ color: 'red', paddingLeft: '30px', fontSize: '20px' }} className="fa-sharp fa-solid fa-trash"></i>}</TableCell>
                                            </MenuItem>
                                        </Menu>

                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                component="div"
                count={userExpenseInfo?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />

            <Dialog
                open={open2}
                onClose={handleCloseEdit}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className='expense-edit'
            >
                <div className="expense">
                    <form onSubmit={handleSubmit} action="" className="signup-form">
                        <div className="form1">
                            <div className="mb-3 expense-dropdown1 space_input">
                                <label
                                    className="lable_bold"
                                    style={{ fontSize: "16px" }}
                                    htmlFor=""
                                >
                                    Expense Type
                                    <i className="fa-sharp fa-light fa-asterisk"></i>
                                </label>
                                <Form.Select
                                    style={{ cursor: "pointer" }}
                                    value={formData.expense}
                                    name="expense"
                                    onChange={handleChange}
                                    type="selact"
                                    required
                                    className="drop-area1"
                                    aria-label="Default select example"
                                >
                                    <option value="">Select</option>
                                    {chartOfAccount &&
                                        chartOfAccount.map((item, index) => {
                                            return (
                                                <option key={index} value={item.name}>
                                                    {item.name}
                                                </option>
                                            );
                                        })}
                                </Form.Select>
                            </div>

                            <div className="mb-3 space_input">
                                <label
                                    className="lable_bold"
                                    style={{
                                        fontSize: "16px",
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                    htmlFor=""
                                >
                                    Expense Date
                                    <i className="fa-sharp fa-light fa-asterisk"></i>
                                </label>
                                <input
                                    style={{ cursor: "pointer" }}
                                    defaultValue={formData.date}
                                    onChange={handleChange}
                                    required
                                    type="date"
                                    name="date"
                                    id=""
                                    className="expense-date"
                                />
                            </div>
                            <div className="mb-3 space_input">
                                <label
                                    className="lable_bold"
                                    style={{
                                        fontSize: "16px",
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                    htmlFor=""
                                >
                                    Amount
                                    <i className="fa-sharp fa-light fa-asterisk"></i>
                                </label>
                                <input
                                    value={formData.amount}
                                    onChange={handleChange}
                                    required
                                    type="text"
                                    name="amount"
                                    id="amount"
                                    className="amount"
                                />
                            </div>
                            <div className="mb-3 expense-dropdown4 space_input">
                                <label
                                    className="lable_bold"
                                    style={{
                                        fontSize: "16px",
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                    htmlFor=""
                                >
                                    Project
                                    <i className="fa-sharp fa-light fa-asterisk"></i>
                                </label>
                                <Form.Select
                                    value={formData.project}
                                    style={{ cursor: "pointer" }}
                                    onChange={handleChange}
                                    name="project"
                                    required
                                    className="drop-area4"
                                    aria-label="Default select example"
                                >
                                    <option value="">Select</option>
                                    {projectInfo &&
                                        projectInfo.map((item, index) => {
                                            return (
                                                <option key={index} value={item.project}>
                                                    {item.project}
                                                </option>
                                            );
                                        })}
                                </Form.Select>
                            </div>

                        </div>

                        <div className="form2">

                            <div className="mb-3 expense-dropdown5 space_input">
                                <label
                                    className="lable_bold"
                                    style={{
                                        fontSize: "16px",
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                    htmlFor=""
                                >
                                    Paid by
                                    <i className="fa-sharp fa-light fa-asterisk"></i>
                                </label>
                                <Form.Select
                                    value={formData.paidBy}
                                    style={{ cursor: "pointer" }}
                                    onChange={handleChange}
                                    name="paidBy"
                                    required
                                    className="drop-area5"
                                    aria-label="Default select example"
                                >
                                    <option value="">Select</option>
                                    <option value="Paid by Me">Paid by Me</option>
                                    <option value="Paid by Company">Paid by Company</option>
                                </Form.Select>
                            </div>

                            <div className="space_input">
                                <label
                                    className="lable_bold"
                                    style={{
                                        fontSize: "16px",
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                    htmlFor=""
                                >
                                    Description
                                </label>
                                <textarea

                                    onChange={handleChange}
                                    type="text"
                                    name="description"
                                    id=""
                                    className="text-area"
                                >
                                    {dataNeedToEdit?.description}
                                </textarea>
                            </div>

                            <div className="expenseBtn expenseBtn_1">
                                <button type="submit" className="btn5" style={{ height: '40px' }} >
                                    Update
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </Dialog>

            <Dialog
                open={open3}
                keepMounted
                onClose={handleClose3}
                aria-describedby="alert-dialog-slide-description"
            >
                <div style={{ padding: "50px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                    <h4 style={{ marginBottom: "40px" }}>Please choose the format</h4>
                    <div style={{ marginBottom: "40px" }} className="button-container">
                        <ExpenseUserPdf userTimesheetInfo={selectedPrint} />
                        <ExpenseUserExcel userTimesheetInfo={selectedPrint} />
                        <ExpenseUserCsv userTimesheetInfo={selectedPrint} />
                    </div>
                    <div className="button-container">
                        <button className='button-container-button' onClick={handleClose3} style={{ cursor: "pointer", background: "rgb(100, 228, 142)", }}>Close</button>
                    </div>
                </div>
            </Dialog>

        </Paper>
    );
}
