import axios from 'axios';
import React, { useState, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { Col, Container, Row, Form } from "react-bootstrap";
import "../admin/TimesheetAprove.css"
import { MyContext } from '../../context/MyProvider'

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

import {
  Dialog,
} from "@mui/material";

const columns = [
  { id: "name", label: "Name", minWidth: 100 },
  { id: "email", label: "Email", minWidth: 170 },
  { id: "phone", label: "Phone", minWidth: 170 },
  { id: "role", label: "Role", minWidth: 170 },
  { id: "source", label: "Source", minWidth: 170 },
  { id: "password", label: "Password", minWidth: 170 },
  { id: "company", label: "Company", minWidth: 170 },
  {
    id: "createdAt",
    label: "createdAt",
    minWidth: 170,
    align: "center",
  },
  {
    id: "login",
    label: "Login",
    minWidth: 100,
    align: "center",
  },
];

const columns2 = [
  { id: "name", label: "Name", minWidth: 100 },
  { id: "email", label: "Email", minWidth: 170 },
  { id: "phone", label: "Phone", minWidth: 170 },
  { id: "role", label: "Role", minWidth: 170 },
  { id: "password", label: "Password", minWidth: 170 },
  {
    id: "login",
    label: "Login",
    minWidth: 100,
    align: "center",
  },
];


const data = [
  {
    task: "Development",
    status: "Active",
    createdAt: "2023-06-07T04:12:17.986Z",
  },
  {
    task: "Development",
    status: "Active",
    createdAt: "2023-06-07T04:12:17.986Z",
  },
  {
    task: "Development",
    status: "Active",
    createdAt: "2023-06-07T04:12:17.986Z",
  },
];

const AdminDetailsTrack = () => {

  const adminDetailsAccountInfo = JSON.parse(localStorage.getItem("adminDetailsAccountInfo"));
  if (adminDetailsAccountInfo) {
    var userID = adminDetailsAccountInfo?._id;
    var token = adminDetailsAccountInfo?.token
    var userName = adminDetailsAccountInfo?.name
  }

  //config

  const config = {
    headers: {
      Accept: "application/json",
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`
    },
  };

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [superAdmin, setSuperAdmin] = useState([]);
  const [subAdmin, setSubAdmin] = useState([]);
  const [manager, setManager] = useState([]);
  const [user, setUser] = useState([]);

  const { setLoading } = useContext(MyContext);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose1 = () => {
    setOpen(false);
  };



  const navigate = useNavigate()

  const varifyDetailsControler = async () => {
    try {
      const { data } = await axios.get(`https://app.timetraker.com/backend/api/varify-details-track-account`, config)
    } catch (error) {
      // navigate("/login")
    }
  }

  const getAllSuperAdmins = async () => {
    try {
      const { data } = await axios.get(`https://app.timetraker.com/backend/api/get-all-super-admin`, config)
      if (data) {
        setSuperAdmin(data.allsuperAdmin)
      }
    } catch (error) {
      alert("Something went wrong")
    }
  }

  const handleClickForOneUser = async (id) => {
    handleClickOpen()
    try {
      const { data } = await axios.get(`https://app.timetraker.com/backend/api/get-all-users/?_id=${id}`, config)
      if (data) {
        setSubAdmin(data.allSubAdmin)
        setManager(data.allManager)
        setUser(data.allUser)
      }
    } catch (error) {
      alert("Something went wrong")
    }
  }

  const handleLoginAdmin = async (item) => {
    localStorage.removeItem("adminLoginInfo")
    localStorage.removeItem("userLoginInfo")
    try {
      setLoading(true)
      const { data } = await axios.post(`https://app.timetraker.com/backend/api/user-login`, {email: item.email, password: item.password}, config)
      if (data.admin) {
        const currentDate = new Date();
        data.admin.logTime = currentDate
        setLoading(false)
        localStorage.setItem('adminLoginInfo', JSON.stringify(data.admin));
        const adminLoginInfo = JSON.parse(localStorage.getItem('adminLoginInfo'));
        if (adminLoginInfo?.accessibility?.myTimesheets === true) {
          navigate('/admin-dashboard-v2')
        }
      } else {
        const currentDate = new Date();
        data.user.logTime = currentDate
        setLoading(false)
        localStorage.setItem('userLoginInfo', JSON.stringify(data.user));
        const userLoginInfo = JSON.parse(localStorage.getItem('userLoginInfo'));
        if (userLoginInfo) {
          navigate('/my-time')
        }
      }
    }
    catch (error) {
      setLoading(false)
      if (error?.response?.data?.result == "Lock") {
        alert('This account has been locked for next 30 minutes')
      } else {
        console.log("error from user login", error)
        alert('Please Refresh the page and try again')
      }
    }
  }


  useEffect(() => {
    varifyDetailsControler()
    getAllSuperAdmins()
  }, [])
  return (
    <div>

      <Row>
        <Col md={12} className="dash-container3">
          <div className="header-top3">
            <div className="dash-header1">
              <h5 style={{ paddingLeft: "60px", paddingTop: "4px" }}>
                User's Data
              </h5>
            </div>
          </div>
        </Col>
      </Row>


      <div style={{ padding: "70px 40px" }}>
        <Paper sx={{ width: "100%", overflow: "auto" }}>
          <TableContainer>
            <Table
              stickyHeader
              aria-label="sticky table"
              style={{ overflow: "hidden", cursor: "pointer" }}
            >
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                        background: "#04542C",
                        color: "#fff",
                        textAlign: "center",
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody
                style={{ overflow: "hidden", cursor: "pointer" }}
              >
                {superAdmin?.map((item, index) => {
                  const createdAt = new Date(item?.createdAt);

                  const options = {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                    hour12: false,
                    timeZone: 'Asia/Kolkata'
                  };

                  const formattedDate = createdAt.toLocaleString('en-IN', options);

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={index}
                      onClick={() => handleClickForOneUser(item?._id)}
                    >
                      <TableCell align="center">{item?.name}</TableCell>
                      <TableCell align="center">{item?.email}</TableCell>
                      <TableCell align="center">{item?.phone}</TableCell>
                      <TableCell align="center">{item?.role}</TableCell>
                      <TableCell align="center">{item?.refer ? item?.refer : "Empty"}</TableCell>
                      <TableCell align="center">{item?.password}</TableCell>
                      <TableCell align="center">{item?.companyName}</TableCell>
                      <TableCell align="center">
                        {formattedDate}
                      </TableCell>
                      <TableCell onClick={() => { handleLoginAdmin(item) }} align="center">
                        <i  class="fa-solid fa-right-to-bracket"></i>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>

          <Dialog
            style={{ padding: "40px" }}
            open={open}
            onClose={handleClose1}
            aria-describedby="alert-dialog-slide-description"
          >
            {/* users table  */}
            <div style={{ padding: "20px" }}>
              <div style={{ padding: "20px 0px" }}> <h4>Admin</h4> </div>
              <Paper sx={{ width: "100%", overflow: "auto" }}>
                <TableContainer>
                  <Table
                    stickyHeader
                    aria-label="sticky table"
                    style={{ overflow: "hidden", cursor: "pointer" }}
                  >
                    <TableHead>
                      <TableRow>
                        {columns2.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{
                              minWidth: column.minWidth,
                              background: "#04542C",
                              color: "#fff",
                              textAlign: "center",
                            }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody
                      style={{ overflow: "hidden", cursor: "pointer" }}
                    >
                      {subAdmin?.map((item, index) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={index}
                          >
                            <TableCell align="center">{item?.name}</TableCell>
                            <TableCell align="center">{item?.email}</TableCell>
                            <TableCell align="center">{item?.phone}</TableCell>
                            <TableCell align="center">{item?.role}</TableCell>
                            <TableCell align="center">{item?.password}</TableCell>
                            <TableCell onClick={() => { handleLoginAdmin(item) }} align="center">
                        <i  class="fa-solid fa-right-to-bracket"></i>
                      </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
              <div style={{ padding: "20px 0px" }}> <h4>Manager</h4> </div>
              <Paper sx={{ width: "100%", overflow: "auto" }}>
                <TableContainer>
                  <Table
                    stickyHeader
                    aria-label="sticky table"
                    style={{ overflow: "hidden", cursor: "pointer" }}
                  >
                    <TableHead>
                      <TableRow>
                        {columns2.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{
                              minWidth: column.minWidth,
                              background: "#04542C",
                              color: "#fff",
                              textAlign: "center",
                            }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody
                      style={{ overflow: "hidden", cursor: "pointer" }}
                    >
                      {manager?.map((item, index) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={index}
                          >
                            <TableCell align="center">{item?.name}</TableCell>
                            <TableCell align="center">{item?.email}</TableCell>
                            <TableCell align="center">{item?.phone}</TableCell>
                            <TableCell align="center">{item?.role}</TableCell>
                            <TableCell align="center">{item?.password}</TableCell>
                            <TableCell onClick={() => { handleLoginAdmin(item) }} align="center">
                        <i  class="fa-solid fa-right-to-bracket"></i>
                      </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>

              <div style={{ padding: "20px 0px" }}> <h4>User</h4> </div>
              <Paper sx={{ width: "100%", overflow: "auto" }}>
                <TableContainer>
                  <Table
                    stickyHeader
                    aria-label="sticky table"
                    style={{ overflow: "hidden", cursor: "pointer" }}
                  >
                    <TableHead>
                      <TableRow>
                        {columns2.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{
                              minWidth: column.minWidth,
                              background: "#04542C",
                              color: "#fff",
                              textAlign: "center",
                            }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody
                      style={{ overflow: "hidden", cursor: "pointer" }}
                    >
                      {user?.map((item, index) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={index}
                          >
                            <TableCell align="center">{item?.name}</TableCell>
                            <TableCell align="center">{item?.email}</TableCell>
                            <TableCell align="center">{item?.phone}</TableCell>
                            <TableCell align="center">{item?.role}</TableCell>
                            <TableCell align="center">{item?.password}</TableCell>
                            <TableCell onClick={() => { handleLoginAdmin(item) }} align="center">
                        <i  class="fa-solid fa-right-to-bracket"></i>
                      </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </div>





            {/* users table  */}





          </Dialog>
        </Paper>
      </div>

    </div>
  )
}

export default AdminDetailsTrack
