import React from 'react'
import './Loder.css'

const LoderForExpenseDownload = () => {
  return (
    <div>
      <div style={{ position: "fixed", top: "45%", left: "50%", zIndex: "23" }}>
        {/* <div class="time-6"></div> */}
        <div className="time-5"></div>
        <p className='loderText'>Sending Expenses in QuickBooks</p>
      </div>
    </div>
  )
}

export default LoderForExpenseDownload