import React, { useState, useContext, useEffect } from 'react'
import { Container, Col, Row } from 'react-bootstrap'
import { Form, Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import '../Prakashcomponent/LoginForm.css'
import timerTrakerlogo from "../../img/TimeTrakerLogo.png"


const AdminChatLogin = () => {


    const config = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        }
    }

    const [formData, setFromData] = useState({});

    const navigate = useNavigate()



    const handleChange = (e) => {
        const { name, value } = e.target;
        setFromData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };




    const login = async (formData) => {
        try {
            const { data } = await axios.post(`https://app.timetraker.com/backend/api/admin-chat-account-login`, formData, config)

            if (data.adminChatAccountInfo) {

                localStorage.setItem('adminChatAccountInfo', JSON.stringify(data.adminChatAccountInfo));
                const adminChatAccountInfo = JSON.parse(localStorage.getItem('adminChatAccountInfo'));
                if (adminChatAccountInfo?._id) {
                    navigate('/admin-chat-suport')
                }
            }
        }
        catch (error) {
            if (error?.response?.data?.result === "Lock") {
                alert('This account has been locked for next 30 minutes')
            } else {
                console.log("error from user login", error)
                alert('invalid email and password')
            }
        }
    }



    const handleSubmit = async (e) => {
        e.preventDefault();
        login(formData)

    }


    return (
        <div className='admin_chat_login_card'>
            <div className='login_form_main_parent login_form_main_parent_another' >
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                    {/* <h3 className='logo_right_log'>Logo</h3> */}
                    <img style={{ width: '70px' }} src={timerTrakerlogo} alt='' />

                    <p className='right_heading_log '>Simplify your time and expense management today.</p>
                    {/* <p className='right_heading_log2'>No credit card required. Cancel anytime.</p> */}
                </div>
                <div className='form_parent_log'>
                    <Form onSubmit={handleSubmit} className='mt-4' >
                        <Row>
                            <Form.Group className="mb-1 form-group-email" controlId="formGroupEmail">
                                <Form.Label>Email<i className="fa-sharp fa-light fa-asterisk"></i></Form.Label>
                                <Form.Control onChange={handleChange} name="email" type="email" />
                            </Form.Group>
                        </Row>

                        <Row className="mb-3">
                            <Form.Group className='form-group-email' as={Col} controlId="formGroupEmail">
                                <Form.Label>Password<i className="fa-sharp fa-light fa-asterisk"></i></Form.Label>
                                <Form.Control onChange={handleChange} name="password" type="password" />
                            </Form.Group>
                        </Row>

                        <div className="d-grid gap-5 headBtn1">
                            <Button type='submit'>Login</Button>
                            <Button style={{ border: '1px solis #64E48E' }}  className='btnhover'>Forgot Password?</Button>
                            {/* <Button style={{ border: '1px solis #64E48E' }} onClick={signInWithIntuite} className=' btnhover btnhover-intuite'>Sign in With Intuite</Button> */}
                            {/* <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                            <img onMouseEnter={() => (setHoverImg(true))} style={{ height: "32px", cursor: "pointer" }} onMouseLeave={() => (setHoverImg(false))} onClick={signInWithIntuite} src={hoverImg ? signInWithIntuiteHover : signInWithIntuiteDefault} />
                        </div> */}
                        </div>
                        <div className='head-bottom-text1'>
                            <p className='text  mt-1' style={{ fontSize: '15px', marginBottom: "0px" }} >Did not have a account? Contact with admin.</p>

                        </div>

                    </Form>
                </div>
                <div>
                    <p className='need_ass' style={{ fontSize: "16px", fontFamily: 'Inter', marginTop: "0px" }}> <span style={{ color: "#2385DF", cursor: 'pointer', fontFamily: 'Inter' }}> Need Assistance ?</span></p>
                </div>



            </div>
        </div >
    )
}

export default AdminChatLogin
