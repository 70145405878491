import React from 'react'
import "./NewLearmMore.css"
import img1 from "./img/Group 38221.png"
import img2 from "./img/hero1.png"
import img3 from "./img/keyhead.png"
import img4 from "./img/key1.png"
import img5 from "./img/key2.png"
import img6 from "./img/key3.png"
import img7 from "./img/key4.png"
import img8 from "./img/key5.png"
import img9 from "./img/wave1.png"
import img10 from "./img/why.png"
import img11 from "./img/box1.png"
import img12 from "./img/box2.png"
import img13 from "./img/box3.png"
import img14 from "./img/box4.png"
import img15 from "./img/box5.png"
import img16 from "./img/wave2.png"
import img17 from "./img/how.png"
import img18 from "./img/list 1.png"
import img19 from "./img/clock.png"
import img20 from "./img/checked.png"
import img21 from "./img/test.png"
import img22 from "./img/test1.png"
import img23 from "./img/test2.png"
import img24 from "./img/test3.png"
import img25 from "./img/hourglass (1) 4.png"
import { useState,useRef } from 'react'

const NewLearmMore = () => {



    const [click, setClick] = useState(true);

    const handleClick = () => {
      setClick(!click);
    };


    const [formData, setFormData] = useState({
        name: '',
        email: '',
        contact: '',
        company: '',
        website: ''
      });
    
      const [errors, setErrors] = useState([]);
      const [success, setSuccess] = useState(false);
    
      const formRef = useRef(null);
    
      const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
      };
    
      const handleSubmit = async (event) => {
        event.preventDefault();
    
        // Clear previous errors
        setErrors([]);
    
        const { name, email, contact, company, website } = formData;
    
        // Validations
        let validationErrors = [];
    
        if (name.trim() === '') {
          validationErrors.push({ field: 'name', message: 'Name is required' });
        }
    
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        if (!emailPattern.test(email)) {
          validationErrors.push({ field: 'email', message: 'Invalid email format' });
        }
    
        const contactPattern = /^\d{10}$/;
        if (!contactPattern.test(contact)) {
          validationErrors.push({ field: 'contact', message: 'Contact number must be 10 digits' });
        }
    
        const urlPattern = /^(https?:\/\/)?([\w-]+\.)+[\w-]{2,4}\/?$/;
        if (website.trim() !== '' && !urlPattern.test(website)) {
          validationErrors.push({ field: 'website', message: 'Invalid website URL' });
        }
    
        if (validationErrors.length > 0) {
          setErrors(validationErrors);
          return;
        }
    
        // If no errors, send the data
        try {
            formRef.current.reset();
            setSuccess(true);
          const response = await fetch('mail.php', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData),
          });
    
          if (response.ok) {
            console.log('Mail sent');
           
           
          }
        } catch (error) {
          console.error('Error sending mail:', error);
        }
      };
    


    const handleClickImage = () => {
        const videoOverlay = document.getElementById('videoOverlay');
        const youtubeVideo = document.getElementById('youtubeVideo');

        youtubeVideo.src = 'https://www.youtube.com/embed/emXKNNwc5V8?autoplay=1&si=Gu2y3NX48pKIQbxh';
        videoOverlay.style.display = 'flex';
    };

    const handleClickClose = () => {
        const videoOverlay = document.getElementById('videoOverlay');
        const youtubeVideo = document.getElementById('youtubeVideo');

        youtubeVideo.src = '';
        videoOverlay.style.display = 'none';
    };


    function redirectToSign() {
        window.location.href = "https://app.timetraker.com/signup";
    }

    function redirectToLog() {
        window.location.href = "https://app.timetraker.com/login";
    }
    return (
        <div>

            <div className='navbar-container-learn'>
                <div className="nav-logo">
                    <img style={{width: "80%", marginTop: "5px", marginLeft: "10px"}} src={img1} alt="" />
                </div>

                <ul id="nav-menu-learn" className={click ? "nav-menu-learn " : "nav-menu-learn active"}>
                    <a href="#home">
                        <li>Home</li>
                    </a>
                    <a href="#features">
                        <li> Features </li>
                    </a>
                    <a href="#pricing">
                        <li>Pricing</li>
                    </a>
                    <a href="#suport">
                        <li>Support</li>
                    </a>
                </ul>

                <div className="nav-button-learn">
                    <button onClick={redirectToLog} className='nav-btn1'>Sign In</button>
                    <button onClick={redirectToSign} className='nav-btn2'>Try Free</button>
                </div>

                <div onClick={handleClick} className="hamburger" id="hamburger">
                    <i className="fas fa-bars hamburger_icon"></i>

                </div>

            </div>


            <div id="home" className="heroSection1">
                <h1>Effortless Time Tracking & Seamless QuickBooks Online Integration</h1>
                <h3>Simplify Time Tracking with Time Traker for QuickBooks Online!</h3>
                <div className="nav-button-learn hero1Buttons">
                    <a href="#suport"> <button style={{marginRight: "6px", marginLeft: "0px"}} className='nav-btn2'>Book a
                        Demo</button></a>
                    <button onClick={redirectToSign} style={{marginLeft: "6px"}} className='nav-btn2'>Free Trial</button>
                </div>
                <p>No credit card required</p>

                <div>
                    <img onClick={handleClickImage} id="heroImage" src={img2} alt="" />
                </div>
            </div>

            <div id="videoOverlay" className="overlay">
                <span onClick={handleClickClose} className="close">&times;</span>
                <div className="video-container">
                    <iframe id="youtubeVideo" width="100%" height="100%" src="" frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen></iframe>
                </div>
            </div>

            <div className="hero2">
                <div id="features" className="hero_head">
                    <img src={img3} alt="" />
                </div>


                <div className="hero_section_3">

                    <div className="container">

                        <div className="hero_section_3 hero_section_4_5">
                            <div className="container">
                                <div className="row hero_section_3_row hero_section_4_row">
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 hero_section_3_left_col">
                                        <h2>Integration with QuickBooks in real-time
                                        </h2>
                                        <ul>
                                            <li> Our system effortlessly integrates with QuickBooks online within seconds,
                                                enabling seamless incorporation into your existing suite with minimal effort.
                                            </li>

                                        </ul>

                                        <button onClick={redirectToSign} className="try">
                                            Integrate Now
                                        </button>
                                    </div>
                                    <div
                                        className="col-12 col-sm-12 col-md-12 col-lg-6 hero_section_3_right_col hero_section_4_left_col">
                                        <img src={img4} alt="" />
                                    </div>

                                    <div className="try_for_small_parent">
                                        <button onClick={redirectToSign} className="try try_for_small">
                                            Integrate Now
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="row hero_section_3_row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 hero_section_3_left_col">
                                <h2>Easy Timesheet Entry
                                </h2>
                                <ul>
                                    <li>Easily create invoices from your timesheets and seamlessly integrate them into
                                        QuickBooks in just few seconds. Simplify your payroll by sending timesheets directly to
                                        QuickBooks.</li>

                                </ul>

                                <button onClick={redirectToSign} className="try">
                                    Set your time
                                </button>
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 hero_section_3_right_col">
                                <img src={img5} alt="" />
                            </div>

                            <div className="try_for_small_parent">
                                <button onClick={redirectToSign} className="try try_for_small">
                                    Set your time
                                </button>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="hero_section_3 hero_section_4_5">
                    <div className="container">
                        <div className="row hero_section_3_row hero_section_4_row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 hero_section_3_left_col">
                                <h2>Expense Tracking and Management
                                </h2>
                                <ul>
                                    <li> Easily monitor both reimbursable and corporate credit card expenses for your team,
                                        whether online or on your device. Our software seamlessly integrates with QuickBooks
                                        online in seconds.</li>

                                </ul>

                                <button onClick={redirectToSign} className="try">
                                    See your expense
                                </button>
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 hero_section_3_right_col hero_section_4_left_col">
                                <img src={img6} alt="" />
                            </div>

                            <div className="try_for_small_parent">
                                <button onClick={redirectToSign} className="try try_for_small">
                                    See your expense
                                </button>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="hero_section_3 hero_section_4_5">
                    <div className="container">
                        <div className="row hero_section_3_row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 hero_section_3_left_col">
                                <h2>Detailed Reporting and analytics
                                </h2>
                                <ul>
                                    <li> Experience powerful, customizable reporting capabilities to analyze your time data in
                                        any way you need. Set targets or budgets for individuals, teams, clients, and monitor
                                        real-time progress as you and your team log time.</li>

                                </ul>

                                <button onClick={redirectToSign} className="try">
                                    Set your task
                                </button>
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 hero_section_3_right_col">
                                <img src={img7} alt="" />
                            </div>

                            <div className="try_for_small_parent">
                                <button onClick={redirectToSign} className="try try_for_small">
                                    Set your task
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="hero_section_3 hero_section_4_5">
                    <div className="container">
                        <div className="row hero_section_3_row hero_section_4_row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 hero_section_3_left_col">
                                <h2>On -The-Go Mobile app Tracking
                                </h2>
                                <ul>
                                    <li> With us, your team can easily clock in and out with a single tap, ensuring precise time
                                        tracking. This guarantees accurate records of tasks completed, who performed them, and
                                        the time spent on each job.</li>

                                </ul>

                                <button onClick={redirectToSign} className="try">
                                    Track Now
                                </button>
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 hero_section_3_right_col hero_section_4_left_col">
                                <img src={img8} alt="" />
                            </div>

                            <div className="try_for_small_parent">
                                <button onClick={redirectToSign} className="try try_for_small">
                                    Track Now
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div className="waveback1">
                <img src={img9} alt="" />
            </div>
            <div className="hero3">
                <div className="hero_head">
                    <img src={img10} alt="" />
                </div>

                <div className="container">
                    <div className="row hero3_row">
                        <div className="col-12 col-sm-12 col-md-6 col-lg-4 hero3_col">
                            <img src={img11} alt="" />
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 col-lg-4 hero3_col">
                            <img src={img12} alt="" />
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 col-lg-4 hero3_col">
                            <img src={img13} alt="" />
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 col-lg-4 hero3_col">
                            <img src={img14} alt="" />
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 col-lg-4 hero3_col">
                            <img src={img15} alt="" />
                        </div>
                    </div>
                </div>

            </div>
            <div className="waveback2">
                <img src={img16} alt="" />
            </div>

            <div className="hero4">
                <div className="content">
                    <div className="container">
                        <div className="row hero4_row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-7 hero4_left ">
                                <div className="hero_head">
                                    <img src={img17} alt="" />
                                </div>
                                <p><img src={img18} alt="" />Invite users and set up accounts</p>
                                <p><img src={img18} alt="" />Enter timesheets and expenses easily</p>
                                <p><img src={img18} alt="" />Sync seamlessly with QuickBooks Online</p>
                                <p><img src={img18} alt="" />Generate reports and streamline payroll</p>
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-5 hero4_right">
                                <img src={img19} alt="" />
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div id="pricing" className="hero5">
                <h1>Our Pricing is simple with no hidden fees</h1>


                <div className="content_box">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 ">

                                <div className="hero5_left">
                                    <h3>$ 0</h3>
                                    <p>Free Plan for upto 3 users</p>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 ">
                                <div className="hero5_right">
                                    <div>
                                        <img src={img20} alt="" />
                                        <p>Full access of Time Traker</p>
                                    </div>
                                    <div>
                                        <img src={img20} alt="" />
                                        <p>QuickBooks online integration</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="button">
                        <button onClick={redirectToSign}>Try Now</button>
                    </div>
                </div>
            </div>


            <div className="hero6">

                <div className="hero2">
                    <div className="hero_head">
                        <img src={img21} alt="" />
                    </div>
                </div>

                <div className="container">
                    <div className="row">


                        <div className="col-12 col-sm-12 col-md-12 col-lg-4 hero6_col">
                            <div className="testimonial-card">
                                <div className="profile-pic">
                                    <img src={img22} alt="Elena Martin" />
                                </div>
                                <div className="content">
                                    <h2>Elena Martin</h2>
                                    <p className="title">Entrepreneur</p>
                                    <p className="testimonial">
                                        As a manager of a remote team, TimeTraker has been invaluable. It’s easy for my team to
                                        log hours, and I can quickly see who’s working on what. The detailed reports help us
                                        stay on top of projects and budgets.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-sm-12 col-md-12 col-lg-4 hero6_col">

                            <div className="testimonial-card">
                                <div className="profile-pic">
                                    <img src={img23} alt="Kerri Mackay" />
                                </div>
                                <div className="content">
                                    <h2>Kerri Mackay</h2>
                                    <p className="title">Marketing Manager</p>
                                    <p className="testimonial">
                                        Since we started using TimeTraker, our productivity has significantly increased. The
                                        daily, weekly, and anytime tracking options give us flexibility and insight into how
                                        we're spending our time. The ability to analyze daily productivity is a game-changer.
                                    </p>
                                </div>
                            </div>
                        </div>



                        <div className="col-12 col-sm-12 col-md-12 col-lg-4 hero6_col">
                            <div className="testimonial-card">
                                <div className="profile-pic">
                                    <img src={img24} alt="Marc Wolfe" />
                                </div>
                                <div className="content">
                                    <h2>Marc Wolfe</h2>
                                    <p className="title">Web Designer</p>
                                    <p className="testimonial">
                                        TimeTraker has transformed how we handle time tracking in our business. The intuitive
                                        interface and automated calculations have simplified recording tasks, saving us hours
                                        each week. Highly recommend it
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div id="suport" className="hero7">
                <h1>We help you manage your time better</h1>
                <img className="hourglassTop" src={img25} alt="" />

                <form ref={formRef} onSubmit={handleSubmit} id="demoForm" action="">
                    <div>
                        <p>Name</p>
                        <input onChange={handleChange}
                         className={errors.find(error => error.field === 'name') ? 'error' : ''}
                        type="text" id="name" name="name" placeholder="Name" required />
                    </div>
                    <div>
                        <p>E-mail</p>
                        <input onChange={handleChange}
                         className={errors.find(error => error.field === 'email') ? 'error' : ''}
                        type="text" id="email" name="email" placeholder="E-mail" required />
                    </div>
                    <div>
                        <p>Contact No.</p>
                        <input onChange={handleChange}
                         className={errors.find(error => error.field === 'contact') ? 'error' : ''}
                        type="number" id="contact" name="contact" placeholder="Contact No." required />
                    </div>
                    <div>
                        <p>Company Name</p>
                        <input onChange={handleChange} 
                        
                        className={errors.find(error => error.field === 'name') ? 'error' : ''}type="text" id="company" name="company" placeholder="Company Name" required />
                    </div>
                    <div>
                        <p>Website URL</p>
                        <input onChange={handleChange} 
                         className={errors.find(error => error.field === 'website') ? 'error' : ''}
                        type="text" id="website" name="website" placeholder="Website URL" required />
                    </div>
                    <div className="book_demo">
                        <button type="submit">Book a Demo</button>
                    </div>
                    <p id="success" style={success? {display: "block"}: {display: "none"}}>Thank you for submitting the form! We will be in touch with you shortly.</p>

                </form>
                <img className="hourglassButtom" src={img25} alt="" />
            </div>


            <footer>
                <div className="footer_left">
                    <img src={img1} alt="" />
                    <div>
                        <p> <i style={{marginRight: "10px"}} className="fa-solid fa-phone"></i>(818) 492 9970</p>
                        <p><i style={{marginRight: "10px"}} className="fa-solid fa-envelope"></i>Support@timetraker.com</p>
                    </div>
                </div>
                <div className="footer_right">
                    <div className="footer_naviagting">
                        <a href="https://app.timetraker.com/TermsConditions">
                            <p>Terms of Service</p>
                        </a>
                        <a href="https://app.timetraker.com/PrivacyPolicy">
                            <p>Privacy policy</p>
                        </a>

                    </div>
                    <div className="subscribe">
                        <input placeholder="Email address" type="text" name="" id="" />
                        <button onClick={redirectToSign}>Subscribe</button>
                    </div>
                </div>
            </footer>
        </div>
    )
}

export default NewLearmMore
